import { isEmpty, getDeepObjValue, isObject } from "./util";
import { getLocalStorage } from "./storage";

export default class API {
  constructor(options = {}) {
    this.headers = {
      ...{
        Accept: "application/json",
        // 'Content-Type': 'application/json',
      },
      ...options,
    };

    this.proxyAPI = false;
    this.noAuth = true;
    this.redirect = true;
  }

  get = async (route) => {
    return await this.xhr(route, null, "GET");
  };

  put = async (route, params) => {
    const data = isEmpty(params) ? {} : { ...params };
    return await this.xhr(route, data, "PUT");
  };

  post = async (route, params) => {
    let data;
    //data = (params instanceof FormData) === true ? params : isEmpty(params) ? {} : {...params};
    return await this.xhr(route, params, "POST");
  };

  remove = async (route, params) => {
    const data = isEmpty(params) ? {} : { ...params };
    return await this.xhr(route, data, "DELETE");
  };

  xhr = async (route, params, verb) => {
    const auth = getLocalStorage("auth");
    if (
      isEmpty(auth) &&
      [
        /images\/logo\/[a-zA-Z0-9-]+/,
        /profile-photo\/[a-zA-Z0-9-]+/,
        "/all",
        "/reset",
        "/resetpassword",
        "/mobile/signin",
        "/mobile/getcards",
        "/home/mycards",
        "/account/login",
        "/logout",
        "/home/register",
        "/activate",
        "/transaction/validate",
        "/transaction/process",
        "/confirmactivation",
      ].indexOf(route) === -1 &&
      !/confirmactivation\/[a-zA-Z0-9-]+/.test(route) &&
      !/transactionreport\/[a-zA-Z0-9-]+/.test(route) &&
      this.redirect
    ) {
      const app_domain = window.location.origin;
      window.location.replace(`${app_domain}/login`);
      return false;
    }

    if (!isEmpty(getDeepObjValue(auth, "data", "access_token"))) {
      this.headers = {
        ...this.headers,
        Authorization: `Bearer ${auth.data.access_token}`,
      };
    }

    const api_domain =
      /*this.proxyAPI ? process.env.REACT_APP_PROXY_API_URL : */ process.env
        .REACT_APP_API_URL;

    const url = `${api_domain}${route}`;

    let body;
    if (params instanceof FormData) body = params;
    else body = JSON.stringify(params);

    let options = Object.assign(
      { method: verb },
      params ? { body: body } : null
    );

    if (!url.includes("signin")) options.headers = { ...this.headers };

    try {
      const response = await fetch(url, options);

      const result = await response.json();

      let updatedResult = Array.isArray(result) ? { data: result } : result;
      updatedResult =
        (typeof updatedResult).toLowerCase() === "boolean"
          ? { Success: updatedResult }
          : { ...updatedResult };

      if (
        !isEmpty(getDeepObjValue(updatedResult, "Messages")) &&
        isObject(getDeepObjValue(updatedResult, "Messages"))
      ) {
        const [key] = Object.keys(updatedResult.Messages);
        updatedResult = { ...updatedResult, Messages: key };
      }
      const payload = { ...updatedResult, ...{ timestamp: +new Date() } };

      return payload;
    } catch (e) {
      console.log(e);
      throw new Error(e.message);
    }
  };
}
