
import React from "react";
import PropTypes from "prop-types";

const HomeVerificationList = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg"><path d="m28.8409 13.6539c.6104.6103.6104 1.5997 0 2.2097l10.4821 10.4825c-.6104.61011.5995.61012.2098 0l4.98994.9902c-.6104-.6101-.61041.5994 02.2095.61-.6103 1.5994-.6103 2.2094 0l3.8852 3.8852 9.37759.3777c.6103-.6101 1.5997-.6101 2.2097 0zm11.1591 6.3461c0 11.0558.9465 2020 2011.05499 0208.94652020 011.05499 8.9465320 2020 11.055 0 20 8.94653 20 20zm3.125 0c09.32777.548516.87516.87516.8759.3277 016.875 7.548516.875 16.875 0 9.3277 7.5485 16.875 16.875 16.875 9.3277 0 16.8757.5485 16.87516.875z" fill={color}/></svg>
    )
}

HomeVerificationList.defaultProps = {
    color: '#e7792b',
    fontSize: 15
}

HomeVerificationList.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default HomeVerificationList
    