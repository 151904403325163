import React, { lazy, Component } from 'react';
import {withRouter} from 'react-router-dom';
import withStyle from "../../common/jss";
import {classNames} from "../../helper/util";
import { styles } from "./style";
import Footer from './Footer/Footer';



const Navbar = lazy(() => import("./Navbar/Navbar"))
class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const { children, classes, location, showNavbar, match: {path}} = this.props;

        return ( 
            <>
                {
                    showNavbar ? (
                        <Navbar isHome={
                            location.pathname === "/" ||
                            path === "/confirm/:token"
                         } showNavbar={showNavbar}/>
                    ) : null
                }
                <div className={classNames({
                    [classes.contentWrapper]: true,
                    "navbar-less": !showNavbar
                })}>
                    {children}
                </div>
                <Footer isHome={location.pathname === "/"} showNavbar={showNavbar}/>
            </>
         )
    }
}

Layout.defaultProps = {
    showNavbar: true
}
 
export default withStyle(styles)( withRouter(Layout));