
import React from "react";
import PropTypes from "prop-types";

const Search = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M8.11888 16.6173C3.63495 16.6173 0 12.9823 0 8.49839C0 4.01444 3.63495 0.379486 8.11888 0.379486C12.6028 0.379486 16.2378 4.01444 16.2378 8.49839C16.2378 10.3746 15.6014 12.1021 14.5326 13.4769L19.9999 18.9443L18.5647 20.3795L13.0974 14.9122C11.7226 15.9809 9.99506 16.6173 8.11888 16.6173ZM14.2082 8.49839C14.2082 11.8613 11.482 14.5876 8.11901 14.5876C4.75606 14.5876 2.02985 11.8613 2.02985 8.49839C2.02985 5.13543 4.75606 2.40921 8.11901 2.40921C11.482 2.40921 14.2082 5.13543 14.2082 8.49839Z" fill={color}/>
</svg>

    )
}

Search.defaultProps = {
    color: '#E7792B',
    fontSize: 15
}

Search.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Search
    