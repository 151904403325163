import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withStyle from './jss';
import { 
    classNames, 
    isEmpty 
} from '../helper/util';
import ErrorAlert from "../common/icons/ErrorAlert";
import SuccessAlert from "../common/icons/SuccessAlert";

const styles = theme => ({
    alert: {
        "&:not(.center)":{
            position: 'absolute',
            top: '10vh',
            width: "80%",
            left: "10%",
            "& .close":{
                top: -17.5,
                right: -17.5,
            },
        },
        "&.center":{
            maxWidth: 450,
            paddingTop: 30,
            paddingBottom: 30,
            "& .alert-wrapper":{
                flexDirection: "column",
                justifyContent: "center",
                "& .content-wrapper":{
                    flexBasis: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    "& p":{
                        textAlign: "center",
                    }
                }
            },
            "& .close":{
                right: "calc(50% - 240px)",
            },
        },
        padding: '10px 20px',
        fontSize: '15px',
        zIndex: 10,
        visibility: "hidden",
        opacity: 0,
        transition: "all 0.5s",
        
        paddingRight: "5%",
        paddingLeft: "5%",
        "& .close":{
            position: "absolute",
            width: 35,
            height: 35,
            borderRadius: 17.5,
            backgroundColor: "#ff0000",
            color: "#ffffff",
            fontWeight: "bolder",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            userSelect: "none",
            cursor: "pointer",
        },
        "& .alert-wrapper":{
            display: "flex",
            alignItems: "center",
            "& .icon-wrapper":{
                flex: "0 0 80px",
                display: "flex",
                justifyContent: "center",
                [theme.breakpoints.mdDown]:{
                    flexBasis: 60,
                },
                "& img":{
                    [theme.breakpoints.mdDown]:{
                        width: "75%",
                    }
                }
            },
            "& .content-wrapper":{
                flex: "0 0 calc(100% - 80px)",
                [theme.breakpoints.mdDown]:{
                    flexBasis: "calc(100% - 60px)",
                },
                "& h1":{
                    margin: 0,
                    [theme.breakpoints.mdDown]:{
                        fontSize: 18,
                    }
                },
                "& p":{
                    marginTop: 5,
                    marginBottom: 5,
                    color: "#3D474B",
                    [theme.breakpoints.mdDown]:{
                        fontSize: 12,
                    }
                }
            }
        },
        '&.success': {
            backgroundColor: theme.alertSuccess.bgColor,
            color: theme.alertSuccess.color,
        },
        '&.error': {
            backgroundColor: theme.alertError.bgColor,
            color: theme.alertError.color,
        },
        '&.info': {
            backgroundColor: theme.alertInfo.bgColor,
            color: theme.alertInfo.color,
        },
        "&.active":{
            top: '15vh',
            visibility: "visible",
            opacity: 1,
            transition: "all 0.5s",
        }
    },
    alertWrapper: {
        backgroundColor: "rgba(0, 0, 0, 0.35)",
        position: "fixed",
        width: "100vw",
        height: "100vh",
        top: 0,
        zIndex: 10,
        opacity: 0,
        visibility: "hidden",
        transition: "all 0.5s",
        "&.active": {
            opacity: 1,
            visibility: "visible",
        },
        "&.center":{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }
    }
});


class Alert extends Component {

    constructor(){
        super();
        this.state = {
            alert: false,
        }
    }

    componentDidMount(){
        const { alert, duration } = this.props;
        this.delayDisplay = setTimeout(() => {
            this.setState({
                alert
            });
        }, 500)

        this.alertDisplay = setTimeout(() => {
            this.setState({
                alert: false
            });
            this.animationRemove();
        }, duration)
    }

    componentWillUnmount(){
        clearTimeout(this.alertDisplay);
        clearTimeout(this.delayDisplay)
        clearTimeout(this.animateDisplay)
    }

    animationRemove = () => {
        const { onRemoveAlert } = this.props;
        this.animateDisplay = setTimeout(() => {
            this.setState({
                alert: false,
                alertMsg: "",
                alertType: "info"
            });

            if(onRemoveAlert){
                onRemoveAlert();
            }

            clearTimeout(this.delayDisplay );
        }, 500);
        
    }

    onCloseAlert = () => {
        this.animationRemove();
        clearTimeout(this.alertDisplay);
    }

    getIcon = (type) => {
        
        let Icon;
        switch(type){
            case "success":
                Icon = SuccessAlert;
            break;
            case "error":
                Icon = ErrorAlert;
            break;
            default:
                Icon = null
        }

        return !isEmpty(Icon)  ? (
        <Icon fontSize="3.5em" />
        ) : null;
    }

    getHeader = (type) => {
        const { title } = this.props;
        let header;
        switch(type){
            case "success":
                header = "Success";
            break;
            case "error":
                header = "Failed";
            break;
            case "info":
                header = "Info";
            break;
            default:
                header = null
        }
        return !isEmpty(title, true) ? title : header;
    }

    render(){
        const { alertMsg, alertType, classes, center, button} = this.props;
        const { alert } = this.state;
        
            return (
            <div className={
                classNames({
                    [classes.alertWrapper]: true,
                    "active": alert,
                    "center": center,
                })
                }>
                <div className={classNames({
                    [classes.alert]: true,
                    [alertType]: true,
                    "active": alert,
                    "center": center,
                })}>
                    <span className="close" onClick={this.onCloseAlert}>X</span>
                    <div className="alert-wrapper">
                        <div className="icon-wrapper">
                            { this.getIcon(alertType)}
                        </div>
                        <div className="content-wrapper">
                            <h1>{this.getHeader(alertType)}</h1>
                            <p>{alertMsg}</p>
                        </div>
                {
                    !isEmpty(button) ? button : null
                }
                    </div>
                </div>
            </div>
            )
    }
}

Alert.propTypes = {
    alert: PropTypes.bool,
    alertMsg: PropTypes.string,
    alertType: PropTypes.oneOf(['success', 'error', 'info']),
    duration: PropTypes.number,
    onRemoveAlert: PropTypes.func,
    title: PropTypes.string,
    center: PropTypes.bool,

}

Alert.defaultProps = {
    duration: 10000,
    center: false
}

export default withStyle(styles)(Alert);