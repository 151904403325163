
import React from "react";
import PropTypes from "prop-types";

const PersonalAssistance = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.6172 13.4766C23.2644 13.4766 23.7891 12.9519 23.7891 12.3047C23.7891 11.6575 23.2644 11.1328 22.6172 11.1328C21.97 11.1328 21.4453 11.6575 21.4453 12.3047C21.4453 12.9519 21.97 13.4766 22.6172 13.4766Z" fill={color}/>
<path d="M25.3125 2.34375H23.7891V1.17188C23.7891 0.524648 23.2644 0 22.6172 0C21.97 0 21.4453 0.524648 21.4453 1.17188V2.34375H16.1133V1.17188C16.1133 0.524648 15.5886 0 14.9414 0C14.2942 0 13.7695 0.524648 13.7695 1.17188V2.34375H8.49609V1.17188C8.49609 0.524648 7.97145 0 7.32422 0C6.67699 0 6.15234 0.524648 6.15234 1.17188V2.34375H4.6875C2.10281 2.34375 0 4.44656 0 7.03125V25.3125C0 27.8972 2.10281 30 4.6875 30H13.6523C14.2996 30 14.8242 29.4754 14.8242 28.8281C14.8242 28.1809 14.2996 27.6562 13.6523 27.6562H4.6875C3.39516 27.6562 2.34375 26.6048 2.34375 25.3125V7.03125C2.34375 5.73891 3.39516 4.6875 4.6875 4.6875H6.15234V5.85938C6.15234 6.5066 6.67699 7.03125 7.32422 7.03125C7.97145 7.03125 8.49609 6.5066 8.49609 5.85938V4.6875H13.7695V5.85938C13.7695 6.5066 14.2942 7.03125 14.9414 7.03125C15.5886 7.03125 16.1133 6.5066 16.1133 5.85938V4.6875H21.4453V5.85938C21.4453 6.5066 21.97 7.03125 22.6172 7.03125C23.2644 7.03125 23.7891 6.5066 23.7891 5.85938V4.6875H25.3125C26.6048 4.6875 27.6562 5.73891 27.6562 7.03125V13.7109C27.6562 14.3582 28.1809 14.8828 28.8281 14.8828C29.4754 14.8828 30 14.3582 30 13.7109V7.03125C30 4.44656 27.8972 2.34375 25.3125 2.34375Z" fill={color}/>
<path d="M22.9102 15.8203C19.0008 15.8203 15.8203 19.0008 15.8203 22.9102C15.8203 26.8195 19.0008 30 22.9102 30C26.8195 30 30 26.8195 30 22.9102C30 19.0008 26.8195 15.8203 22.9102 15.8203ZM22.9102 27.6562C20.2932 27.6562 18.1641 25.5272 18.1641 22.9102C18.1641 20.2931 20.2932 18.1641 22.9102 18.1641C25.5271 18.1641 27.6562 20.2931 27.6562 22.9102C27.6562 25.5272 25.5271 27.6562 22.9102 27.6562Z" fill={color}/>
<path d="M24.6094 21.7383H24.082V20.5078C24.082 19.8606 23.5574 19.3359 22.9102 19.3359C22.2629 19.3359 21.7383 19.8606 21.7383 20.5078V22.9102C21.7383 23.5574 22.2629 24.082 22.9102 24.082H24.6094C25.2566 24.082 25.7812 23.5574 25.7812 22.9102C25.7812 22.2629 25.2566 21.7383 24.6094 21.7383Z" fill={color}/>
<path d="M17.5195 13.4766C18.1667 13.4766 18.6914 12.9519 18.6914 12.3047C18.6914 11.6575 18.1667 11.1328 17.5195 11.1328C16.8723 11.1328 16.3477 11.6575 16.3477 12.3047C16.3477 12.9519 16.8723 13.4766 17.5195 13.4766Z" fill={color}/>
<path d="M12.4219 18.5742C13.0691 18.5742 13.5938 18.0496 13.5938 17.4023C13.5938 16.7551 13.0691 16.2305 12.4219 16.2305C11.7747 16.2305 11.25 16.7551 11.25 17.4023C11.25 18.0496 11.7747 18.5742 12.4219 18.5742Z" fill={color}/>
<path d="M7.32422 13.4766C7.97143 13.4766 8.49609 12.9519 8.49609 12.3047C8.49609 11.6575 7.97143 11.1328 7.32422 11.1328C6.67701 11.1328 6.15234 11.6575 6.15234 12.3047C6.15234 12.9519 6.67701 13.4766 7.32422 13.4766Z" fill={color}/>
<path d="M7.32422 18.5742C7.97143 18.5742 8.49609 18.0496 8.49609 17.4023C8.49609 16.7551 7.97143 16.2305 7.32422 16.2305C6.67701 16.2305 6.15234 16.7551 6.15234 17.4023C6.15234 18.0496 6.67701 18.5742 7.32422 18.5742Z" fill={color}/>
<path d="M7.32422 23.6719C7.97143 23.6719 8.49609 23.1472 8.49609 22.5C8.49609 21.8528 7.97143 21.3281 7.32422 21.3281C6.67701 21.3281 6.15234 21.8528 6.15234 22.5C6.15234 23.1472 6.67701 23.6719 7.32422 23.6719Z" fill={color}/>
<path d="M12.4219 23.6719C13.0691 23.6719 13.5938 23.1472 13.5938 22.5C13.5938 21.8528 13.0691 21.3281 12.4219 21.3281C11.7747 21.3281 11.25 21.8528 11.25 22.5C11.25 23.1472 11.7747 23.6719 12.4219 23.6719Z" fill={color}/>
<path d="M12.4219 13.4766C13.0691 13.4766 13.5938 12.9519 13.5938 12.3047C13.5938 11.6575 13.0691 11.1328 12.4219 11.1328C11.7747 11.1328 11.25 11.6575 11.25 12.3047C11.25 12.9519 11.7747 13.4766 12.4219 13.4766Z" fill={color}/>
</svg>

    )
}

PersonalAssistance.defaultProps = {
    color: '#01122B',
    fontSize: 15
}

PersonalAssistance.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default PersonalAssistance
    