
import React from "react";
import PropTypes from "prop-types";

const Others = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="35" height="10" viewBox="0 0 35 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.53829 9.07666C2.03584 9.07666 0 7.04074 0 4.53829C0 2.03584 2.03584 0 4.53829 0C7.04098 0 9.0769 2.03584 9.0769 4.53829C9.0769 7.04074 7.04105 9.07666 4.53829 9.07666ZM4.53829 1.66667C2.95494 1.66667 1.66667 2.95494 1.66667 4.53829C1.66667 6.12165 2.95494 7.40991 4.53829 7.40991C6.12196 7.40991 7.41023 6.12165 7.41023 4.53829C7.41023 2.95494 6.12196 1.66667 4.53829 1.66667Z" fill={color}/>
<path d="M17.5002 9.07659C14.9978 9.07659 12.9619 7.04074 12.9619 4.53829C12.9619 2.03584 14.9978 0 17.5002 0C20.0027 0 22.0385 2.03584 22.0385 4.53829C22.0385 7.04074 20.0027 9.07659 17.5002 9.07659ZM17.5002 1.66659C15.9169 1.66659 14.6286 2.95486 14.6286 4.53821C14.6286 6.12157 15.9169 7.40984 17.5002 7.40984C19.0836 7.40984 20.3718 6.12157 20.3718 4.53821C20.3718 2.95486 19.0836 1.66659 17.5002 1.66659Z" fill={color}/>
<path d="M30.4617 9.07659C27.959 9.07659 25.9231 7.04074 25.9231 4.53829C25.9231 2.03584 27.9589 0 30.4617 0C32.9641 0 35 2.03584 35 4.53829C35 7.04074 32.9641 9.07659 30.4617 9.07659ZM30.4617 1.66659C28.878 1.66659 27.5898 2.95486 27.5898 4.53821C27.5898 6.12157 28.878 7.40984 30.4617 7.40984C32.0451 7.40984 33.3333 6.12157 33.3333 4.53821C33.3333 2.95486 32.0451 1.66659 30.4617 1.66659Z" fill={color}/>
</svg>

    )
}

Others.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

Others.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Others
    