import React, { Component } from 'react';
import {Link} from "react-router-dom";
import Logo from "../../../assets/img/png/logo.png";

import Twitter from "../../../common/icons/Twitter";
import Facebook from "../../../common/icons/Facebook";
import Youtube from "../../../common/icons/Youtube";
import Instagram from "../../../common/icons/Instagram";
import Whatsapp from "../../../common/icons/Whatsapp";
import Linkedin from "../../../common/icons/Linkedin";

import { styles } from "./style";

import withStyle from '../../../common/jss';
import Button from '../../../common/Button';

import {classNames} from "../../../helper/util";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    render() { 
        const { classes, showNavbar, isHome } = this.props;
        return ( 
            <div className={ classNames({[classes.footer]: true, "home": isHome})}>
                <div className="footer-wrapper">
                    <div className="top-footer-wrapper">
                        <div className="box">
                        <Link to="/"><img src={Logo} alt="Payarena Logo"/></Link>
                            <p>Experience a world of more options with Payarena your one-stop platform to Buy and Sell.</p>
                            <div className="social-media-icons">
                                <Button ariaLabel="Twitter" className="social-media">
                                    <span>
                                        <a href={" https://twitter.com/Payarena_ng"}>
                                            <Twitter fontSize="2em" />
                                        </a>
                                        
                                    </span></Button>
                                <Button ariaLabel="Facebook" className="social-media"><span><a href={"https://www.facebook.com/Payarena/"}> <Facebook fontSize="2em"  /></a></span></Button>
                                <Button ariaLabel="Youtube" className="social-media"><span><Youtube fontSize="2em"  /></span></Button>
                                <Button ariaLabel="Instagram" className="social-media"><span><a href={" https://www.instagram.com/payarena_ng/"}><Instagram fontSize="2em"  /></a> </span></Button>
                                <Button ariaLabel="Whatsapp" className="social-media"><span><Whatsapp fontSize="2em"  /></span></Button>
                                <Button ariaLabel="LinkedIn" className="social-media"><a href={" https://www.linkedin.com/company/payarena"}> <span><Linkedin fontSize="2em"  /></span></a></Button>
                            </div>
                        </div>
                        {
                            !showNavbar ? (

                        <div className="box">
                            <div className="menu-list">
                                <h2 className="menu-item-header">Sitemap</h2>
                                <Link className="menu-item" to="/">Home</Link>
                                <a 
                                    className="menu-item" 
                                    href="https://mall.payarena.com/"
                                    target="_blank" rel="noopener noreferrer" 
                                >Payarena&nbsp;Mall</a>
                                <Link className="menu-item" to="/contact-us">Contact</Link>
                                <Link className="menu-item" to="/faq">FAQs</Link>
                            </div>
                        </div>
                            ) : null
                        }
                    </div>
               
                    <div className="bottom-footer">
                     <p>Copyright © {(new Date()).getFullYear()} PayArena. All rights reserved.</p>
                    </div>
                </div>
            </div>
         )
    }
}
 
export default withStyle(styles)(Footer);