import React, {Component} from 'react';
import { create } from 'jss';
import preset from 'jss-preset-default';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { StateContext } from './react-jss'
import { deepPropsExist, isEmpty } from '../../helper';

const jss = create();
jss.setup(preset())

const withStyle = (styles, data = {}) => WrappedComponent => {
    class withStyleHOC extends Component {
        static contextType = StateContext;
        render(){
            const [{theme}] = this.context;
            const newTheme = !isEmpty(data) && Object.prototype.toString(data) === '[object Object]' ? {...theme, ...data} : {...theme};
            
            const innerStyle = typeof styles === 'function' ? styles(newTheme) : styles;
            let {classes} = jss.createStyleSheet(
                innerStyle ).attach();
            if(deepPropsExist(this.props, 'classes')){
                classes = {...this.props.classes, ...classes};
            }
            return <WrappedComponent theme={theme} classes={classes} {...this.props}/>
        }
    }
    hoistNonReactStatic(withStyleHOC, WrappedComponent);

    return withStyleHOC
}

export default withStyle;