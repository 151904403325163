
import React from "react";
import PropTypes from "prop-types";

const Credit = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12.5" cy="12.5" r="12.5" fill={color}/>
<path d="M16.4645 16.0209L16.4645 14.7746L11.528 14.7702L16.9064 9.39176L16.0225 8.50788L10.6441 13.8863L10.6397 8.94982L9.3934 8.94982V16.0209H16.4645Z" fill={color}/>
</svg>

    )
}

Credit.defaultProps = {
    color: '#E1FFF4',
    fontSize: 15
}

Credit.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Credit
    