
import React from "react";
import PropTypes from "prop-types";

const ErrorAlert = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M54.5454 0H5.45458C2.44764 0 0 2.44623 0 5.45458V54.5454C0 57.5536 2.44764 60 5.45458 60H54.5454C57.5524 60 60 57.5538 60 54.5454V5.45458C59.9999 2.44623 57.5524 0 54.5454 0ZM57.2726 54.5454C57.2726 56.0488 56.0501 57.2726 54.5454 57.2726H5.45458C3.94977 57.2726 2.72735 56.0488 2.72735 54.5454V5.45458C2.72735 3.95117 3.94989 2.72735 5.45458 2.72735H54.5454C56.0502 2.72735 57.2726 3.95117 57.2726 5.45458V54.5454Z" fill={color}/>
<path d="M43.2363 16.7628C42.7036 16.2301 41.8407 16.2301 41.3081 16.7628L29.9996 28.0714L18.691 16.7628C18.1583 16.2301 17.2954 16.2301 16.7628 16.7628C16.2301 17.2955 16.2301 18.1585 16.7628 18.691L28.0714 29.9996L16.7628 41.3081C16.2301 41.8408 16.2301 42.7037 16.7628 43.2363C17.0291 43.5026 17.3781 43.6358 17.727 43.6358C18.0759 43.6358 18.4247 43.5026 18.6912 43.2363L29.9997 31.9278L41.3082 43.2363C41.5745 43.5026 41.9235 43.6358 42.2724 43.6358C42.6213 43.6358 42.9702 43.5026 43.2366 43.2363C43.7693 42.7036 43.7693 41.8407 43.2366 41.3081L31.928 29.9996L43.2366 18.691C43.769 18.1585 43.769 17.2955 43.2363 16.7628Z" fill={color}/>
</svg>

    )
}

ErrorAlert.defaultProps = {
    color: '#EB5757',
    fontSize: 15
}

ErrorAlert.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default ErrorAlert
    