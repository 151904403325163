import { API } from "../../helper";
import { SEND_OTP, CHANGE_PASSWORD } from "../constant";

const api = new API();
api.noAuth = false;
api.proxyAPI = true;

export const onSendOTP = (data = {}) => {
    return async dispatch => {
        try{
            let response = await api.post("/sendotp", data);
            
            if(response.Success){
                response = {...response, Messages: "Your OTP has been sent to your mail or phone"};
            }

            return dispatch({
                type: SEND_OTP,
                payload: response
            })
        }catch(e){
            return dispatch({
                type: SEND_OTP,
                payload: {
                    Success: false,
                    Messages: "There was an error sending OTP, Try again",
                    timestamps: +(new Date())
                },
            })
        }
    }
}

export const onChangePassword = (data={}) => {
    return async dispatch => {
        try{
            let response = await api.post("/home/password", data);

            return dispatch({
                type: CHANGE_PASSWORD,
                payload: response
            });

        }catch(e){
            console.log(e);
            return dispatch({
                type: CHANGE_PASSWORD,
                payload: {
                    Success: false,
                    Messages: "Something is wrong, changing password",
                    timestamps: +(new Date())
                }
            })
        }
    }
}