import { API, getLocalStorage, isEmpty, setLocalStorage } from '../../helper';
import {
    GET_BULK_MERCHANTS,
    GET_CATEGORIES
} from '../constant';

const api = new API();

export const getCategories = () => {
    return async dispatch => {
        try{
            const categories = process.env.REACT_APP_CATEGORIES_NAME || "categories";
            const getCategories = getLocalStorage(categories);
            let payload;
            if(!isEmpty(getCategories)){
                payload = {data: getCategories, timestamp: +(new Date())};
            }else{
                payload = await api.get('/all');
                setLocalStorage(categories, payload.data, 60 * 60);
            }
            dispatch({
                type: GET_CATEGORIES,
                payload : {
                    Success: true,
                    ...payload
                },
            })
        }catch(err){
            dispatch({
                type: GET_CATEGORIES,
                payload : {
                    Success: false,
                    message: "Error Fetching categories",
                    timestamps: +(new Date())
                },
            })
        }
    }
}
export const getMerchants = () => {
    return async dispatch => {
        try{
            const bulkMerchants =  "merchants";
            const getBulkMerchants = getLocalStorage(bulkMerchants);
            let payload;
            if(!isEmpty(getBulkMerchants)){
                payload = {data: getBulkMerchants, timestamp: +(new Date())};
            }else{
                payload = await api.get('/bulkMerchants');
                setLocalStorage(bulkMerchants, payload.data, 60 * 60);
            }
            dispatch({
                type: GET_BULK_MERCHANTS,
                payload : {
                    Success: true,
                    ...payload
                },
            })
        }catch(err){
            dispatch({
                type: GET_BULK_MERCHANTS,
                payload : {
                    Success: false,
                    message: "Error Fetching records",
                    timestamps: +(new Date())
                },
            })
        }
    }
}
