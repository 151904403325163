
import React from "react";
import PropTypes from "prop-types";

const MobileShow = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} fill="none" height="18" viewBox="0 0 27 18" width="27" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="m0 0v3h27v3zm0 18.0001h6v3h6zm187.5002h18v2.99995h18z" fill={color} fillRule="evenodd"/></svg>
    )
}

MobileShow.defaultProps = {
    color: '#3d474b',
    fontSize: 15
}

MobileShow.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default MobileShow
    