
import React from "react";
import PropTypes from "prop-types";

const Whatsapp = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.1877 4.26465C7.42304 4.26465 5.18774 6.49994 5.18774 9.26465C5.18774 10.1764 5.45245 11.0882 5.92304 11.8529L6.04069 12.0588L5.54068 13.9117L7.42304 13.4117L7.62892 13.5294C8.39363 13.9999 9.27598 14.2352 10.1877 14.2352C12.9524 14.2352 15.1877 11.9999 15.1877 9.23523C15.2172 6.49994 12.9524 4.26465 10.1877 4.26465ZM13.1289 11.147C12.9524 11.5882 12.4819 11.8823 12.0113 11.9705C11.5701 12.0588 11.1877 11.9117 10.776 11.7646C10.3936 11.647 10.0407 11.4705 9.71715 11.2647C9.04068 10.8529 8.48186 10.3235 8.01127 9.67641C7.86422 9.47053 7.74657 9.29406 7.62892 9.08818C7.48186 8.82347 7.3348 8.52935 7.24657 8.23524C7.21716 8.11759 7.18774 7.97053 7.18774 7.85289C7.18774 7.3823 7.36422 6.91171 7.71716 6.61759C7.92304 6.44112 8.24657 6.3823 8.48186 6.49994C8.74657 6.647 8.86421 7.02936 9.01127 7.26465C9.09951 7.47053 9.21716 7.73524 9.18775 7.97053C9.15833 8.20583 8.98186 8.3823 8.8348 8.55877C8.74657 8.67641 8.71716 8.76465 8.80539 8.8823C9.27598 9.67642 9.95245 10.2646 10.8054 10.5882C10.923 10.647 11.0113 10.6176 11.0995 10.5294C11.2466 10.3529 11.3936 9.99994 11.6289 9.8823C11.923 9.76465 12.2466 9.94112 12.5113 10.0882C12.7466 10.2352 13.1583 10.4117 13.2172 10.7058C13.2172 10.8823 13.1877 11.0294 13.1289 11.147Z" fill={color}/>
<path d="M9.86377 0C4.59906 0 0.36377 4.26471 0.36377 9.5C0.36377 14.7647 4.62847 19 9.86377 19C15.1285 19 19.3638 14.7353 19.3638 9.5C19.3932 4.26471 15.1285 0 9.86377 0ZM10.1873 15.4412C9.15789 15.4412 8.15789 15.1765 7.24612 14.7059L3.89318 15.6176L4.77553 12.2647C4.27553 11.3529 4.01083 10.3235 4.01083 9.29412C4.01083 5.88235 6.77553 3.11765 10.1873 3.11765C13.5991 3.11765 16.3638 5.88235 16.3638 9.29412C16.3638 12.6765 13.5991 15.4412 10.1873 15.4412Z" fill={color}/>
</svg>

    )
}

Whatsapp.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

Whatsapp.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Whatsapp
    