
import React from "react";
import PropTypes from "prop-types";

const Telecomms = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="23" height="35" viewBox="0 0 23 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.8871 31.6133H19.0643L13.6557 11.0618C14.6166 10.3401 15.242 9.19485 15.242 7.90375C15.242 5.7248 13.4688 3.95215 11.2904 3.95215C9.11201 3.95215 7.33881 5.7248 7.33881 7.90375C7.33881 9.19485 7.96424 10.3401 8.92512 11.0618L3.51648 31.6133H1.69366C1.38136 31.6133 1.12915 31.8664 1.12915 32.1779V34.4359C1.12915 34.7474 1.38136 35.0004 1.69366 35.0004H20.8871C21.1994 35.0004 21.4517 34.7474 21.4517 34.4359V32.1779C21.4517 31.8664 21.1994 31.6133 20.8871 31.6133ZM7.5527 20.7114L7.87162 19.5L11.2904 16.936L14.7092 19.5L15.0281 20.7114L11.2904 23.0723L7.5527 20.7114ZM10.233 23.7402L6.06314 26.3742L7.25198 21.8573L10.233 23.7402ZM8.33415 17.7422L8.99844 15.2171L10.3499 16.2303L8.33415 17.7422ZM12.2309 16.2303L13.5824 15.2171L14.2467 17.7422L12.2309 16.2303ZM15.3294 21.8567L16.5185 26.3734L12.3483 23.7394L15.3294 21.8567ZM8.46783 7.90375C8.46783 6.34747 9.73413 5.08118 11.2904 5.08118C12.8467 5.08118 14.113 6.34747 14.113 7.90375C14.113 9.46002 12.8467 10.7263 11.2904 10.7263C9.73413 10.7263 8.46783 9.46002 8.46783 7.90375ZM11.2904 11.8553C11.7629 11.8553 12.2141 11.768 12.6344 11.615L13.2725 14.0384L11.2904 15.5247L9.30909 14.0384L9.94693 11.615C10.3667 11.768 10.818 11.8553 11.2904 11.8553ZM5.6414 27.9757L11.2904 24.4078L16.9394 27.9757L17.897 31.6133H4.68383L5.6414 27.9757ZM20.3226 33.8714H2.25818V32.7424H20.3226V33.8714Z" fill={color}/>
<path d="M11.2902 9.59705C12.2238 9.59705 12.9838 8.8371 12.9838 7.9035C12.9838 6.9699 12.2238 6.20996 11.2902 6.20996C10.3566 6.20996 9.59668 6.9699 9.59668 7.9035C9.59668 8.8371 10.3566 9.59705 11.2902 9.59705ZM11.2902 7.33899C11.6017 7.33899 11.8547 7.59258 11.8547 7.9035C11.8547 8.21443 11.6017 8.46802 11.2902 8.46802C10.9787 8.46802 10.7257 8.21443 10.7257 7.9035C10.7257 7.59258 10.9787 7.33899 11.2902 7.33899Z" fill={color}/>
<path d="M16.9354 6.20917H18.0644C18.0644 5.27557 17.3044 4.51562 16.3708 4.51562V5.64465C16.6823 5.64465 16.9354 5.89824 16.9354 6.20917Z" fill={color}/>
<path d="M19.1934 6.21039H20.3224C20.3224 4.03144 18.5492 2.25879 16.3708 2.25879V3.38782C17.9271 3.38782 19.1934 4.65411 19.1934 6.21039Z" fill={color}/>
<path d="M16.3708 0V1.12903C19.1725 1.12903 21.4515 3.40858 21.4515 6.20965H22.5805C22.5805 2.78591 19.7946 0 16.3708 0Z" fill={color}/>
<path d="M5.64539 6.20917C5.64539 5.89824 5.89842 5.64465 6.2099 5.64465V4.51562C5.2763 4.51562 4.51636 5.27557 4.51636 6.20917H5.64539Z" fill={color}/>
<path d="M6.20965 2.25879C4.03126 2.25879 2.25806 4.03144 2.25806 6.21039H3.38708C3.38708 4.65411 4.65338 3.38782 6.20965 3.38782V2.25879Z" fill={color}/>
<path d="M6.20965 1.12903V0C2.78591 0 0 2.78591 0 6.20965H1.12903C1.12903 3.40858 3.40803 1.12903 6.20965 1.12903Z" fill={color}/>
</svg>

    )
}

Telecomms.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

Telecomms.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Telecomms
    