import { 
    CHANGE_SIDEBAR_STATUS,
    CHANGE_SIDEBAR_SELECT } from '../constant'; 

export const sidebarReducer = (state, action) => {
    switch (action.type) {
        case CHANGE_SIDEBAR_STATUS:
            return {
                ...state,
                activateSidebar: action.payload
            };
        case CHANGE_SIDEBAR_SELECT:
            return {
                ...state,
                lastUpdate: action.payload
            };
        default:
        return state;
    }
};