
import React from "react";
import PropTypes from "prop-types";

const GenerateInvoice = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.0145 34.9734H6.42627V37.7675H31.0145V34.9734Z" fill={color}/>
<path d="M23.1448 12.4803H14.5297C13.758 12.4803 13.1326 13.1058 13.1326 13.8774C13.1326 14.6491 13.758 15.2745 14.5297 15.2745H23.1448C23.9165 15.2745 24.5419 14.6491 24.5419 13.8774C24.5419 13.1058 23.9165 12.4803 23.1448 12.4803Z" fill={color}/>
<path d="M29.6644 19H14.5297C13.758 19 13.1326 19.6254 13.1326 20.3971C13.1326 21.1688 13.758 21.7942 14.5297 21.7942H29.6645C30.4362 21.7942 31.0615 21.1688 31.0615 20.3971C31.0615 19.6254 30.4361 19 29.6644 19Z" fill={color}/>
<path d="M37.1768 0.170147C36.6752 -0.0552559 36.0889 0.0337325 35.6772 0.397405L31.765 3.85838L27.8481 0.355917C27.3177 -0.118639 26.5158 -0.118639 25.9854 0.355917L22.0736 3.85415L18.1614 0.355917C17.6309 -0.118639 16.8295 -0.118639 16.2991 0.355917L12.3822 3.85838L8.46994 0.397405C8.05876 0.0341778 7.47199 -0.0548106 6.97042 0.170147C6.46936 0.395995 6.14711 0.894301 6.14711 1.44382V26.3115H1.3971C0.625443 26.3115 0 26.9369 0 27.7086V31.1081C0 34.78 2.98739 37.7674 6.65929 37.7674V34.9732C4.52787 34.9732 2.79412 33.239 2.79412 31.1081V29.1056H24.6814V31.1081C24.6814 34.78 27.6688 37.7674 31.3407 37.7674C35.0126 37.7674 38 34.7801 38 31.1081V1.44374C38.0001 0.894226 37.6778 0.395995 37.1768 0.170147ZM35.206 31.1081C35.206 33.239 33.4718 34.9732 31.3408 34.9732C29.2098 34.9732 27.4756 33.239 27.4756 31.1081V27.7086C27.4756 26.9369 26.8502 26.3115 26.0785 26.3115H8.94123V4.54476L11.462 6.77496C11.9929 7.2444 12.791 7.24202 13.3187 6.76984L17.2304 3.27161L21.1422 6.76939C21.6727 7.24395 22.4745 7.24395 23.005 6.76939L26.9172 3.27116L30.829 6.76939C31.357 7.24158 32.1553 7.24343 32.6856 6.77452L35.206 4.54483V31.1081Z" fill={color}/>
</svg>

    )
}

GenerateInvoice.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

GenerateInvoice.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default GenerateInvoice
    