import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { isEmpty, classNames } from '../helper/util';
import withStyle from './jss';
import {InputText} from "primereact/inputtext";

const styles = theme => ({
    form: {
        flex: 1,
        marginRight: '30px',
        marginLeft: '30px'
    },
    formControl: {
        width: '100%',
        marginBottom: '30px',
        "&.addon-right, &.addon-left":{
            "& > div":{
                display: "flex",
                "& input":{
                    flex: "0 0 calc(100% - 100px)"
                },
                "& .right-button":{
                    flex: "0 0 100px",
                    fontSize: 12,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: theme.color.primary,
                    color: "#ffffff",
                    fontWeight: "bold",
                    userSelect: "none",
                    cursor: "pointer",
                    textTransform: "uppercase",
                }
            },

        },
        "&.addon-left":{
            "& input":{
                paddingLeft: "60px",
            }
        },
        "&.addon-right":{
            "& input":{
                paddingRight: "60px",
            }
        },
        "&.password":{
            "& > div":{
                display: "flex",
                "& input":{
                    flex: "0 0 calc(100% - 50px)"
                },
                "& .password-btn":{
                    flex: "0 0 50px",
                    fontSize: 12,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: theme.color.primary,
                    color: "#ffffff",
                    fontWeight: "bold",
                    userSelect: "none",
                    cursor: "pointer",
                }
            }
        },
        "& label":{
            display: "block",
            fontSize: 14,
            marginBottom: 5,
            marginLeft: -5,
            "&.is-required":{
                "&:after":{
                    content: "'*'",
                    color: "red",
                    marginLeft: 5,
                }
            }
        },
        "& input, & textarea":{
            padding: 20,
            boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.15)",
            backgroundColor: "#FBFEFF",
            fontSize: "1.05em",
        },
        "& textarea":{
            resize: "none"
        },
    },
    formInput: {
        width: '100%',
        outline: 0,
        border: 0,
        "&[type=number]::-webkit-outer-spin-button, &[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            appearance: "none",
            margin: 0,
        },
        /* Firefox */
        "input[type=number]": {
            "-moz-appearance": "textfield", 
        },
        "&.disabled":{
            backgroundColor: "#dadada"
        },
    },
    errorMessage: {
        color: '#ff0000',
        backgroundColor: "#ffffff",
        fontSize: "0.8em",
        padding: 5,
        display: "inline-block",
        marginTop: "5px",
        borderRadius: "3px",
    },
    formTextarea: {
        resize: "none",
        width: '100%',
        padding: '10px',
        fontSize: '15px',
        borderRadius: '3px',
        outline: 0,
        border: 0,
        boxShadow: '0 0 3px rgba(0, 0, 0, 0.3)',
    }
})

const changePasswordType = (show) => {
    return show ? "text" : "password"
}

const Input = (props) => { 
    const { 
        type,onChange,placeholder,name,value,autoComplete,error,classes,formControl,formInput,
        errorMessage,children,addonRight,addonLeft,className,rows,disabled,label,required,
        rightLabel, onBlur, ariaLabel, id, onClick
    } = props;



    
    const [changePassword, onChangePassword] = useState(false)

    return ( 
            <div className={classNames({
                [classes.formControl]: true,
                [formControl]: !isEmpty(formControl) ,
                "addon-left": addonLeft,
                "addon-right": addonRight,
                [className]: !isEmpty(className, true),
                "marginless": props.marginless,
                "password": type === "password"
            })}>
            {
                !isEmpty(label) ? (
                <label className={classNames({
                    "is-required": required
                })}>{label}</label>
                ): null
            }
            {children}
            <div>
            {
                type === "textarea" ? (
                    <textarea
                    className={classNames({
                        [classes.formTextarea]: true,
                    })}
                    name={name}
                    placeholder={placeholder}
                    onBlur={onChange}
                    // {...rest}
                    defaultValue={value}
                    rows={rows}
                    ref={el => !isEmpty( props.refer) ? props.refer(el) : null}
                    disabled={disabled}
                    aria-label={ariaLabel || label || name}
                    id={id}

                    />
                ) : (

                    <input
                        className={classNames({
                            [classes.formInput]: true,
                            [formInput]: !isEmpty(formInput, true),
                            "disabled": disabled
                        })}
                        type={ type === "password" ? changePasswordType(changePassword) : type}
                        name={name}
                        placeholder={placeholder}
                        autoComplete={autoComplete ? 'on' : 'off'}
                        defaultValue={value}
                        ref={el => !isEmpty( props.refer) ? props.refer(el) : null}
                        disabled={disabled}
                        onBlur={onBlur}
                        onChange={onChange}
                        onClick={onClick}
                        aria-label={ariaLabel || name}
                        id={id}
                    />
                )
            }
            {
                addonRight ? (
                    <div className="right-button">
                        {rightLabel}
                    </div>
                ): null
            }
            {
                type === "password" ? (
                    <div className="password-btn" onClick={() => onChangePassword(!changePassword)}>
                        {changePassword ? "HIDE" : "SHOW"}
                    </div>
                ): null
            }
            </div>
             {!isEmpty(error, true) ? 
             ( <small className={classNames({
                [classes.errorMessage]: true,
                [errorMessage]: !isEmpty(errorMessage) 
            })}>{error}</small>)
             : null
             }
            
        </div>
        )
}

Input.propTypes = {
    type: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string,
    autoComplete: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number ]),
    error: PropTypes.string,
    require: PropTypes.bool,
    onclick: PropTypes.func
}

Input.defaultProps = {
    type: "text",
    autoComplete: false,
    addonRight: false,
    addonLeft: false,
    rows: 5,
    require: false,
}

export default withStyle(styles)(Input);
