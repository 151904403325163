
import React from "react";
import PropTypes from "prop-types";

const SuccessAlert = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.0743 44.7838C25.7635 44.7838 25.4655 44.6602 25.2458 44.4404L12.5035 31.6986C12.0457 31.2408 12.0457 30.4988 12.5035 30.041L19.1324 23.4121C19.5901 22.9544 20.3322 22.9544 20.7899 23.4121L26.0743 28.6966L39.2103 15.5601C39.4301 15.3404 39.7285 15.2168 40.0393 15.2168C40.3497 15.2168 40.6482 15.3404 40.8679 15.5601L47.4968 22.1895C47.9545 22.6468 47.9545 23.3888 47.4968 23.8466L26.9029 44.4409C26.6832 44.6606 26.3852 44.7838 26.0743 44.7838ZM14.9896 30.87L26.0743 41.9548L45.0111 23.018L40.0393 18.0458L26.9029 31.1822C26.4451 31.64 25.7031 31.64 25.2458 31.1822L19.9614 25.8978L14.9896 30.87Z" fill={color}/>
<path d="M29.9927 60C29.2273 60 28.461 59.9707 27.6933 59.9121C20.5641 59.3655 13.8496 56.2761 8.78677 51.2137C3.12057 45.547 0 38.0136 0 30C0 21.9868 3.12057 14.453 8.78677 8.78677C14.453 3.12057 21.9868 0 30 0C38.0136 0 45.547 3.12057 51.2137 8.78677C56.2761 13.8496 59.3655 20.5641 59.9121 27.6933C60.4527 34.7452 58.4889 41.7741 54.3828 47.4861C54.0051 48.0116 53.2727 48.1311 52.7472 47.7534C52.2217 47.3758 52.1022 46.6434 52.4799 46.1179C60.4166 35.078 59.187 20.0752 49.5561 10.4439C38.773 -0.339203 21.2274 -0.339203 10.4443 10.4439c0.339203 21.2274 -0.339203 38.7726 10.4443 49.5561C20.0752 59.1866 35.0784 60.4166 46.1183 52.4794C46.6438 52.1017 47.3758 52.2217 47.7539 52.7472C48.1316 53.2727 48.0116 54.0047 47.4861 54.3828C42.3958 58.0421 36.259 60 29.9927 60Z" fill={color}/>
<path d="M50.3848 51.5566C50.0762 51.5566 49.7741 51.4312 49.5562 51.2133C49.3383 50.995 49.2129 50.6928 49.2129 50.3848C49.2129 50.0762 49.3383 49.7741 49.5562 49.5562C49.7741 49.3383 50.0762 49.2129 50.3848 49.2129C50.6928 49.2129 50.995 49.3383 51.2133 49.5562C51.4312 49.7741 51.5566 50.0762 51.5566 50.3848C51.5566 50.6928 51.4312 50.995 51.2133 51.2133C50.995 51.4312 50.6928 51.5566 50.3848 51.5566Z" fill={color}/>
</svg>

    )
}

SuccessAlert.defaultProps = {
    color: '#219653',
    fontSize: 15
}

SuccessAlert.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default SuccessAlert
    