import React, { Component } from 'react';
import withStyle from '../jss';
import { styles } from './style';
import NewDropDown from '../NewDropDown';
import { isEmpty, classNames, isEqual } from '../../helper/util';
import Input from '../Input';

const globalNumPerPage = 10;
const diffFronCenter = 2;
const difference = 2;

class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      numOfPage: this.numberOfPages(props.data, globalNumPerPage),
      numPerPage: globalNumPerPage,
      numOfEntries: Array.isArray(props.data) ? props.data.length : 1,
      items: [],
      outerItems: [],
      currentItems: [],
      searchText: '',
      dropdown: this.preparePages(),
      orderByCols: "",
      orderUp: false
    };
  }

  componentDidMount() {
    const { data } = this.props;
    this.setState({
      items: Array.isArray(data) ? [...data] : [],
      outerItems: Array.isArray(data) ? [...data] : [],
      numOfPage: this.numberOfPages(data, globalNumPerPage),
      numOfEntries: Array.isArray(data) ? data.length : 1,
      currentItems: Array.isArray(data) ? data.slice(0, globalNumPerPage) : [],
    });
  }
  
  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (!isEmpty(data) && !isEqual(data, prevProps.data)) {
      this.setState({
        items: Array.isArray(data) ? [...data] : [],
        numOfPage: this.numberOfPages(data, globalNumPerPage),
        numOfEntries: Array.isArray(data) ? data.length : 1,
        currentItems: Array.isArray(data)
          ? data.slice(0, globalNumPerPage)
          : [],
      });
    }
  }

  preparePages = () => {
    const { paginate } = this.props;
    let dropdown = [1, 2, 5, 10, 20, 50, 100];
    dropdown = !isEmpty(paginate) ? dropdown.concat(paginate) : [...dropdown];
    return dropdown.map(numPerPage => ({ key: numPerPage, label: numPerPage }));
  };

  getCurrentItems = (page, numPerPageInput, numOfPages, itemInput) => {
    const { numPerPage, items, numOfPage } = this.state;
    
    const newNumPerPage = !isEmpty(numPerPageInput, false, true)
      ? parseInt(numPerPageInput, 10)
      : numPerPage;
    const startPoint = page - 1 >= 1 ? (page - 1) * newNumPerPage : 0;
    const endPoint = page * newNumPerPage;
    const newNumOfPages = !isEmpty(numOfPages, false, true)
      ? parseInt(numOfPages, 10)
      : numOfPage;

    const currentItems = !isEmpty(itemInput) ? itemInput.slice(startPoint, endPoint) : items.slice(startPoint, endPoint);

    this.setState({
      currentItems,
      page,
      numPerPage: newNumPerPage,
      numOfPage: newNumOfPages,
    });
  };

  numberOfPages = (items, numPerPage) => {
    return Array.isArray(items) ? Math.ceil(items.length / numPerPage) : 1;
  };

  changePagination = (pageItem, page) => {
    if (pageItem.value === page || page.value === '...') return false;

    this.getCurrentItems(pageItem.value);
  };

  orderBy = (key) => {
    const { items, orderUp, orderByCols  } = this.state;
    
    let newItems = []

    if(orderByCols === key){
      newItems = items.sort((a, b) => {
        if(!orderUp){
          return a[key] > b[key] ? -1 : 1
        }
        return a[key] < b[key] ? -1 : 1
      })
    }else{
      newItems = items.sort((a, b) => a[key] > b[key] ? -1 : 1);
    }

    this.setState({
      items: newItems,
      orderByCols: key,
      orderUp: orderByCols === key ? !orderUp : true
    });


    this.getCurrentItems(1)
  }

  renderPaginationNumber = (page, numOfPage) => {
    const { paginateBtnClass } = this.props;

    const beginCenter = page - diffFronCenter < 1 ? 1 : page - diffFronCenter;
    const endCenter =
      page + diffFronCenter > numOfPage ? numOfPage : page + diffFronCenter;
    const beginnerInit = difference;
    const endInit = numOfPage - difference;
    const renderPages = [];

    for (let i = 1; i <= numOfPage; i++) {
      if (
        i <= beginnerInit ||
        (i >= beginCenter && i <= endCenter) ||
        i >= endInit
      ) {
        renderPages.push({ key: i, value: i });
      } else {
        renderPages.push({ key: i, value: '...' });
        i = i > beginnerInit && i < beginCenter ? beginCenter - 1 : endInit;
      }
    }
    return renderPages.map(pageItem => (
      <span
        key={pageItem.key}
        className={classNames({
          active: pageItem.value === page,
          ellipse: pageItem.value === '...',
          [paginateBtnClass]: !isEmpty(paginateBtnClass),
        })}
        onClick={() => this.changePagination(pageItem, page)}
      >
        {pageItem.value}
      </span>
    ));
  };

  renderPagination = (head, actionRender) => {
    const { classes } = this.props;
    const { page, numOfPage, numPerPage, numOfEntries, items } = this.state;
    const numOfData = !isEmpty(head, false, true)
      ? Object.keys(head).length
      : 1;
    const colNum = !isEmpty(actionRender, false, false, false, false, true)
      ? numOfData + 1
      : numOfData;
    const numOfItems = items.length;

    const pageinationText =
      numOfItems < numOfEntries ? ` (filtered of ${numOfEntries} entries)` : '';
    const start = numOfItems === 0 ? 0 : (page - 1) * numPerPage + 1;
    let end = numOfItems <= numPerPage ? numOfItems : page * numPerPage;

    end =
      end > numOfItems
        ? (page - 1) * numPerPage + (numOfItems % numPerPage)
        : end;

    return (
      <tr>
        <td colSpan={colNum}>
          {
            numOfPage > 1 ? (
          <div className="cols">
            <div className={`col-12 ${classes.pagination}`}>
              {this.renderPaginationNumber(page, numOfPage)}
            </div>
          </div>
            ) : null
          }
          <div className="cols ">
            <div className={`${classes.paginationText} col-7`}>
              {`Showing ${start} to ${end} of ${numOfItems} entries${pageinationText}`}
            </div>
            <div className="col-5"></div>
          </div>
        </td>
      </tr>
    );
  };

  onHandlePaginate = data => {
    const { items } = this.state;
    const numOfPages = Math.ceil(items.length / data.key);
    this.getCurrentItems(1, data.key, numOfPages);
  };

  onChangeHandler = (e) => {
    const { value } = e.target;
    const { outerItems, numPerPage, numOfPage } = this.state;
    const { searchableFunc } = this.props;
    const items = outerItems.filter(item => searchableFunc(item, value));
    this.setState({
      searchText: value,
      items
    });

    this.getCurrentItems(1, numPerPage, numOfPage, items);
  }

  render() {
    const {
      thead,
      dataKey,
      classes,
      actionRender,
      searchable,
    } = this.props;
    const {
      currentItems,
      // serchText,
      numPerPage,
      dropdown,
      orderByCols,
      orderUp,
      searchText
    } = this.state;
    return (
      <div className={classes.tableWrapper}>
        <div className={classes.headerWrapper}>
          <div className={classes.search}>
            {
              searchable ? (

                <Input
                  value={searchText}
                  onChange={this.onChangeHandler}
                  formInput={classes.formInput}
                  placeholder="Fiter table by searching"
                  formControl={classes.formControl}
                />
              ) : null
            }
          </div>
          <div className={classes.paginationWrapper}>
            <div className={classes.paginate}>
              <NewDropDown
                data={dropdown}
                wrapperClassName={classes.wrapperClassName}
                onClick={this.onHandlePaginate}
                addtionalLeftAnchor={<p className="special">Number per Page</p>}
              >
                <p>{numPerPage}</p>
              </NewDropDown>
            </div>
            {/* <div className={classes.paginateList}>
                            Paginate List
                        </div> */}
          </div>
        </div>
        <table className={classes.table}>
          <thead>
            <tr>
              {thead.map(theadDatum => (
                <th 
                  key={theadDatum.key} 
                  onClick={() => this.orderBy(theadDatum.key)}
                  className={classNames({
                    "asc": orderByCols === theadDatum.key && orderUp === true,
                    "desc": orderByCols === theadDatum.key && orderUp === false,
                  })}
                >
                  {theadDatum.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems.map((datum, index) => (
              <tr
                key={`${dataKey(datum)}`}
              >
                {thead.map(theadDatum => (
                  <td
                    key={`${dataKey(datum)}-${theadDatum.key}`}
                    data-title={theadDatum.label}
                  >
                    {
                      isEmpty(
                        theadDatum.render, false, false,false, false, true
                        ) ? datum[theadDatum.key] : (
                        theadDatum.render(datum)
                      )
                    }
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>{this.renderPagination(thead, actionRender)}</tfoot>
        </table>
      </div>
    );
  }
}
Table.defaultProps = {
  searchable: true
}

export default withStyle(styles)(Table);
