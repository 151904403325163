import { API } from "../../helper";
import { EWALLET_ACCOUNT } from "../constant";

const api = new API();
api.noAuth = false;
api.proxyAPI = true;

export const onGetEwalletAccount = (data = {}) => {
    return async dispatch => {
        try{
            let response = await api.post("/ewalletdashboard", data);
            

            return dispatch({
                type: EWALLET_ACCOUNT,
                payload: response
            })
        }catch(e){
            return dispatch({
                type: EWALLET_ACCOUNT,
                payload: {
                    Success: false,
                    Messages: "There was an error sending OTP, Try again",
                    timestamps: +(new Date())
                },
            })
        }
    }
}