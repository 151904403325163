
import React from "react";
import PropTypes from "prop-types";

const JambResult = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32.4803 9.04234L21.6044 0.309266C21.3762 0.154633 20.9933 0 20.684 0H1.5316C0.692166 0 0 0.692166 0 1.5316V34.4684C0 35.3078 0.692166 36 1.5316 36H31.5598C32.3993 36 33.0178 35.3078 33.0178 34.4684V10.191C33.0178 9.72714 32.8632 9.34424 32.4803 9.04234ZM22.2156 4.65371L28.1285 9.41788H22.2156V4.65371ZM30.0282 33.0178H3.0632V2.98957H19.1524V10.8832C19.1524 11.7226 19.8446 12.4148 20.684 12.4148H30.0282V33.0178Z" fill={color}/>
<path d="M13.1806 15.5662C12.9892 15.0654 12.5106 14.7415 11.9804 14.7415C11.4502 14.7415 10.9716 15.0728 10.7801 15.5662L5.45634 29.4463C5.19862 30.109 5.52998 30.8527 6.20006 31.1104C6.35469 31.1693 6.50932 31.1988 6.66396 31.1988C7.1794 31.1988 7.67275 30.8822 7.8642 30.3741L9.14545 27.0311H14.8227L16.1039 30.3741C16.3617 31.0368 17.1054 31.3682 17.7681 31.1178C18.4308 30.8601 18.7621 30.1164 18.5118 29.4537L13.1806 15.5662ZM10.1322 24.4465L11.9804 19.6234L13.8286 24.4391H10.1322V24.4465Z" fill={color}/>
<path d="M18.3277 23.1729C18.3277 23.8871 18.902 24.4615 19.6163 24.4615H26.3539C27.0681 24.4615 27.6425 23.8871 27.6425 23.1729C27.6425 22.4586 27.0681 21.8843 26.3539 21.8843H19.6163C18.9094 21.8843 18.3277 22.466 18.3277 23.1729Z" fill={color}/>
</svg>

    )
}

JambResult.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

JambResult.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default JambResult
    