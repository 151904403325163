import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { reducer } from "./redux/reducer";
import * as initialState from "./redux/initialization";

import { isEmpty } from "./helper/util";
import { getLocalStorage } from "./helper/storage";
import { StateProvider } from "./common/jss/react-jss";

import Spinner from "./common/Spinner";
import "./App.css";

import "primereact/resources/themes/md-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import CardStatus from "./pages/CardStatus/CardStatus";

const HomePage = lazy(() => import("./pages/HomePage/HomePage"));
const MallPage = lazy(() => import("./pages/MallPage/MailPage"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const FAQ = lazy(() => import("./pages/FAQ/FAQ"));
const Registration = lazy(() => import("./pages/Registration/Registration"));
const Login = lazy(() => import("./pages/Login/Login"));
const Transaction = lazy(() => import("./pages/Transaction/Transaction"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const PublicAuthRoute = lazy(() =>
  import("./components/Routes/PublicAuthRoute")
);
const PersonalAssistance = lazy(() =>
  import("./pages/PersonalAssistance/PersonalAssistance")
);
const ResetPassword = lazy(() => import("./pages/ResetPassword/ResetPassword"));
const SellOnPayarena = lazy(() =>
  import("./pages/SellOnPayarena/SellOnPayarena")
);
const ResultChecker = lazy(() => import("./pages/ResultChecker/ResultChecker"));
const ChangePassword = lazy(() =>
  import("./pages/ChangePassword/ChangePassword")
);
const Ewallet = lazy(() => import("./pages/Ewallet/Ewallet"));
const Profile = lazy(() => import("./pages/Profile/Profile"));
const Report = lazy(() => import("./pages/Report/Report"));
const BulkPayment = lazy(() => import("./pages/BulkPayment/BulkPayment"));
const Activate = lazy(() => import("./pages/Activate/Activate"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));

function App() {
  const authentication = getLocalStorage("auth");
  const newAuth = !isEmpty(authentication)
    ? {
        ...initialState,
        ...{
          auth: {
            ...{
              auth: {
                ...authentication,
              },
            },
          },
        },
      }
    : { ...initialState };

  return (
    <StateProvider initialState={{ ...newAuth }} reducer={reducer}>
      <BrowserRouter>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <>
              <PublicAuthRoute exact path="/dashboard" component={Dashboard} />
              <PublicAuthRoute
                path="/change-password"
                component={ChangePassword}
              />
              <PublicAuthRoute path="/pa" component={PersonalAssistance} />
              <PublicAuthRoute path="/bulk-payment" component={BulkPayment} />
              <PublicAuthRoute path="/ewallet" component={Ewallet} />
              <PublicAuthRoute path="/user-profile" component={Profile} />
              <Route exact path="/app/mall" component={MallPage} />
              <Route
                exact
                path="/app/contact-us"
                component={Contact}
                strict={true}
              />
              <Route exact path="/app/faq" component={FAQ} />
              <Route path="/app/register-user" component={Registration} />
              <Route path="/app/login/:transactionId?" component={Login} />
              <Route path="/app/login" component={Login} />
              <Route path="/app/forget-password" component={ResetPassword} />
              <Route path="/app/confirm/:token" component={HomePage} />
              <Route path="/report/:reportId" component={Report} />
              <Route
                exact
                path="/app/transactions/:id?"
                component={Transaction}
              />
              {/*<Route path="/app/transactions/:id" component={Transaction}/>*/}
              <Route path="/sell-on-payarena" component={SellOnPayarena} />
              <Route path="/result-checker" component={ResultChecker} />
              <Route path="/activate/:userId" component={Activate} />
              <Route path="/cardstatus/:id" component={CardStatus} />
              <Route exact path="/spectranet" component={HomePage} />
              <Route exact path="/eko electricity" component={HomePage} />
              <Route exact path="/" component={HomePage} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
            </>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </StateProvider>
  );
}

export default App;
