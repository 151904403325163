
import React from "react";
import PropTypes from "prop-types";

const SignOut = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.14471 10.9406L6.96357 7.61457L6.96357 7.61457C7.34182 7.16824 7.28656 6.49973 6.84026 6.1215C6.39398 5.74329 5.72543 5.79829 5.34716 6.24484L1.05003 11.3151C1.05001 11.3151 1.05 11.3151 1.04999 11.3151C0.721855 11.7021 0.711524 12.2857 1.04999 12.6849C1.04999 12.6849 1.05 12.6849 1.05001 12.6849L5.34718 17.7552C5.72481 18.2009 6.3933 18.2572 6.84024 17.8785L6.84026 17.8785C7.28656 17.5003 7.34182 16.8318 6.96357 16.3854L6.81099 16.5147L6.96357 16.3854L4.14471 13.0594H18.9688C19.5539 13.0594 20.0283 12.5852 20.0283 12C20.0283 11.4148 19.5539 10.9406 18.9688 10.9406H4.14471ZM22.1406 0.8H14.6718C14.0866 0.8 13.6124 1.27425 13.6124 1.85938C13.6124 2.4445 14.0866 2.91875 14.6718 2.91875H21.0811V21.0812H14.6718C14.0866 21.0812 13.6124 21.5555 13.6124 22.1406C13.6124 22.7258 14.0866 23.2 14.6718 23.2H22.1406C22.7255 23.2 23.2 22.7258 23.2 22.1406V1.85938C23.2 1.27422 22.7255 0.8 22.1406 0.8Z" fill={color} stroke={color} strokeWidth="0.4"/>
</svg>

    )
}

SignOut.defaultProps = {
    color: '#01122B',
    fontSize: 15
}

SignOut.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default SignOut
    