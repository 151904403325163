import { ADD_PA, GET_USER_PA, RESUME_PA } from '../constant'; 

export const paReducer = (state, action) => {
    switch (action.type) {
        case ADD_PA:
            return {
                ...state,
                addPA: action.payload
            };
        case GET_USER_PA:
            return {
                ...state,
                getPAs: action.payload
            };
        case RESUME_PA:
            return {
                ...state,
                resumePA: action.payload
            };
        default:
        return state;
    }
};