
import React from "react";
import PropTypes from "prop-types";

const CorporatePayment = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.75 40H1.25C0.56 40 0 39.44 0 38.75V10.4167C0 8.80833 1.30833 7.5 2.91667 7.5H15.4167C16.1067 7.5 16.6667 8.06 16.6667 8.75C16.6667 9.44 16.1067 10 15.4167 10H2.91667C2.69 10 2.5 10.19 2.5 10.4167V37.5H17.5V23.2833C17.5 22.5933 18.06 22.0333 18.75 22.0333C19.44 22.0333 20 22.5933 20 23.2833V38.75C20 39.44 19.44 40 18.75 40Z" fill={color}/>
<path d="M15.4167 10H4.58337C3.89337 10 3.33337 9.44004 3.33337 8.75004V6.25004C3.33337 4.64171 4.64171 3.33337 6.25004 3.33337H13.75C15.3584 3.33337 16.6667 4.64171 16.6667 6.25004V8.75004C16.6667 9.44004 16.1067 10 15.4167 10ZM5.83337 7.50004H14.1667V6.25004C14.1667 6.02004 13.98 5.83337 13.75 5.83337H6.25004C6.02004 5.83337 5.83337 6.02004 5.83337 6.25004V7.50004Z" fill={color}/>
<path d="M10 5.83333C9.31 5.83333 8.75 5.27333 8.75 4.58333V1.25C8.75 0.56 9.31 0 10 0C10.69 0 11.25 0.56 11.25 1.25V4.58333C11.25 5.27333 10.69 5.83333 10 5.83333Z" fill={color}/>
<path d="M30.4167 40H16.25C15.56 40 15 39.44 15 38.75C15 38.06 15.56 37.5 16.25 37.5H30.4167C31.1067 37.5 31.6667 38.06 31.6667 38.75C31.6667 39.44 31.1067 40 30.4167 40Z" fill={color}/>
<path d="M25 40C24.31 40 23.75 39.44 23.75 38.75V32.5083C23.75 31.8183 24.31 31.2583 25 31.2583C25.69 31.2583 26.25 31.8183 26.25 32.5083V38.75C26.25 39.44 25.69 40 25 40Z" fill={color}/>
<path d="M25 33.3333C23.1616 33.3333 21.6666 31.8383 21.6666 30C21.6666 28.1616 23.1616 26.6666 25 26.6666C26.8383 26.6666 28.3333 28.1616 28.3333 30C28.3333 31.8383 26.8383 33.3333 25 33.3333ZM25 29.1666C24.54 29.1666 24.1666 29.54 24.1666 30C24.1666 30.46 24.54 30.8333 25 30.8333C25.46 30.8333 25.8333 30.46 25.8333 30C25.8333 29.54 25.46 29.1666 25 29.1666Z" fill={color}/>
<path d="M12.9167 40H7.08337C6.39337 40 5.83337 39.44 5.83337 38.75V31.25C5.83337 30.56 6.39337 30 7.08337 30H12.9167C13.6067 30 14.1667 30.56 14.1667 31.25V38.75C14.1667 39.44 13.6067 40 12.9167 40ZM8.33337 37.5H11.6667V32.5H8.33337V37.5Z" fill={color}/>
<path d="M12.0833 25H7.91662C7.22663 25 6.66663 24.44 6.66663 23.75C6.66663 23.06 7.22663 22.5 7.91662 22.5H12.0833C12.7733 22.5 13.3333 23.06 13.3333 23.75C13.3333 24.44 12.7733 25 12.0833 25Z" fill={color}/>
<path d="M12.0833 15H7.91662C7.22663 15 6.66663 14.44 6.66663 13.75C6.66663 13.06 7.22663 12.5 7.91662 12.5H12.0833C12.7733 12.5 13.3333 13.06 13.3333 13.75C13.3333 14.44 12.7733 15 12.0833 15Z" fill={color}/>
<path d="M12.0833 20H7.91662C7.22663 20 6.66663 19.44 6.66663 18.75C6.66663 18.06 7.22663 17.5 7.91662 17.5H12.0833C12.7733 17.5 13.3333 18.06 13.3333 18.75C13.3333 19.44 12.7733 20 12.0833 20Z" fill={color}/>
<path d="M29.1667 23.3333C23.1934 23.3333 18.3334 18.4733 18.3334 12.5C18.3334 6.52663 23.1934 1.66663 29.1667 1.66663C35.14 1.66663 40 6.52663 40 12.5C40 18.4733 35.14 23.3333 29.1667 23.3333ZM29.1667 4.16663C24.5717 4.16663 20.8334 7.90496 20.8334 12.5C20.8334 17.095 24.5717 20.8333 29.1667 20.8333C33.7617 20.8333 37.5 17.095 37.5 12.5C37.5 7.90496 33.7617 4.16663 29.1667 4.16663Z" fill={color}/>
<path d="M35.4801 13.4682H33.569V11.6315H35.4801C35.7672 11.6315 36 11.3989 36 11.1117C36 10.8245 35.7672 10.5918 35.4801 10.5918H33.569V8.04327C33.569 7.67125 33.4955 7.3982 33.3505 7.23187C33.2128 7.07363 33.0317 7 32.781 7C32.5417 7 32.3674 7.07312 32.2323 7.23037C32.0891 7.39687 32.0164 7.67043 32.0164 8.04344V10.5921H28.4216L26.8931 8.27914C26.7623 8.07272 26.6393 7.87936 26.5198 7.69376C26.4123 7.52661 26.3074 7.39052 26.2083 7.28891C26.1206 7.19894 26.0206 7.12932 25.9037 7.0758C25.7934 7.02545 25.6532 7.00017 25.4873 7.00017C25.2757 7.00017 25.0836 7.05814 24.8997 7.17725C24.7176 7.29506 24.5918 7.44071 24.515 7.62256C24.4475 7.79538 24.4118 8.06019 24.4118 8.40203V10.5919H22.5197C22.2326 10.592 22 10.8246 22 11.1119C22 11.3991 22.2326 11.6316 22.5198 11.6316H24.4119V13.4685H22.5198C22.2326 13.4685 22 13.7011 22 13.9884C22 14.2756 22.2326 14.508 22.5198 14.508H24.4119V17.4178C24.4119 17.7788 24.4877 18.0491 24.638 18.2208C24.7809 18.3846 24.962 18.4606 25.2081 18.4606C25.4454 18.4606 25.6252 18.3843 25.7743 18.2202C25.9269 18.0521 26.0041 17.782 26.0041 17.4178V14.508H29.2571L31.0174 17.2073C31.1398 17.3858 31.2662 17.5659 31.3922 17.7425C31.5059 17.901 31.63 18.0408 31.7609 18.1575C31.8788 18.263 32.0052 18.3404 32.1366 18.3876C32.2718 18.4363 32.4299 18.461 32.6053 18.461C33.0814 18.461 33.5689 18.3152 33.5689 17.2344V14.508H35.48C35.7672 14.508 35.9999 14.2752 35.9999 13.9881C36 13.701 35.7672 13.4682 35.4801 13.4682ZM32.0164 11.6314V13.4681H30.3226L29.1089 11.6314H32.0164ZM26.0041 9.52101L26.7026 10.5918H26.0041V9.52101ZM26.0041 13.4682V11.6315H27.3809L28.5789 13.4682H26.0041ZM32.0164 16.0318L31.0097 14.508H32.0164V16.0318Z" fill={color}/>
</svg>

    )
}

CorporatePayment.defaultProps = {
    color: '#E7792B',
    fontSize: 15
}

CorporatePayment.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default CorporatePayment
    