import React, { useState } from 'react';
import withStyle from './jss';
import { classNames, isEmpty } from "../helper/util";

const styles = theme => ({
    dropdownWrapper: {
        position: "relative",
        "& .custom-dropdown": {
            // minHeight: `${100 * theme.zoom}px`,
            minWidth: `${250 * theme.zoom}px`,
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.3)",
            position: "absolute",
            top: `${60 * theme.zoom}px`,
            right: `10px`,
            borderRadius: `${5 * theme.zoom}px`,
            transition: "all 0.5s",
            opacity: 0,
            zIndex: -1,
            "& .option-item":{
                padding: "10px 15px",
                cursor: "pointer",
                "& p": {
                    margin: 0,
                    color: "#000000",
                    fontSize: `${15 * theme.zoom}px`
                }
            },
            // "& .option-item:not(:last-child)":{
            //     borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
            // },
            "&::before":{
                content: "''",
                position: "absolute",
                top: `-${20 * theme.zoom}px`,
                border: "10px solid transparent",
                borderBottomColor: "#ffffff", 
                right: "10px",
            },
            "&.active": {
                opacity: 1,
                top: `${70 * theme.zoom}px`,
                zIndex: 5,
            },
        },
    },
    anchor: {
        display: "flex",
        justifyContent: "space-between",
        cursor: "pointer",

        "& > div.inner":{
            position: "relative",
            marginRight: "20px",
            "&::after":{
                content: "''",
                position: "absolute",
                right: "-17px",
                border: "6px solid transparent",
                borderTopColor: "#ffffff",
                top: "calc(25% - 3px)",
                
            },
            "&.outline":{
                "&::after":{
                    borderLeft: "1px solid #ffffff",
                    borderBottom: "1px solid #ffffff",
                    transform: "rotate(-45deg)",
                    
                }
            }
        }
    },
})

const onOuterlickHander = (getClickedItem, datum) => {
    if(!isEmpty(getClickedItem)){
        getClickedItem(datum)
    }
}


const NewDropDown = props => {
    const { 
        classes, children, addtionalCenterAnchor, 
        addtionalLeftAnchor, addtionalRightAnchor,
        onClick, data, wrapperClassName, innerClassName,
        outerLeftAnchor
    } = props;

    const [active, onActive] = useState(false);

    return (
        <div className={classNames({
            [classes.dropdownWrapper]: true,
            [wrapperClassName]: !isEmpty(wrapperClassName)
        })}>
            {outerLeftAnchor}
            <div className={classes.anchor}
                onClick={() => onActive(!active)}
                >
                {addtionalLeftAnchor}
                <div className={classNames({
                    "inner": true,
                    [innerClassName]: !isEmpty(innerClassName)
                })}>
                    {children}
                    {addtionalCenterAnchor}
                </div>
                {addtionalRightAnchor}
            </div>
            <div className={
                 classNames({
                    "custom-dropdown": true,
                    "active": active
                })
                }

            >
                {
                    !isEmpty(data) ? data.map(datum => (
                            <div className="option-item" onClick={() => {
                                onActive(!isEmpty(datum.labelHTML));
                                onOuterlickHander(onClick, datum)
                            }}
                            key={datum.key}
                            >
                                {
                                    !isEmpty(datum.labelHTML) ? datum.labelHTML : (<p>{datum.label}</p>)
                                }
                            </div>
                        )) : null
                }
            </div>
        </div>
    )
}


export default withStyle(styles)(NewDropDown);