import {
    CHECK_VALIDATION,
    GET_URL,
    GET_TRANSACTION_REPORT, GET_CARDS, SAVE_CARD
} from "../constant";
import {API, setLocalStorage} from "../../helper";

const api = new API();

api.noAuth = true;

export const checkValidation = (data) => {
    return async dispatch => {
        try{
            var body = getDetailedObject(data)
            const payload = await api.post("/transaction/validate", data);
            
            

            dispatch({
                type: CHECK_VALIDATION,
                payload,
            })
        }catch(e){
            console.log(e);
            dispatch({
                type: CHECK_VALIDATION,
                payload: {
                    payload: {
                        Success: false,
                        Messages: "Error validating transaction",
                        timestamps: +(new Date())
                    }
                }
            })
        }
    }
}

function getDetailedObject(inputObject) {
    var detailedObject = {}, properties;
    var formData = new FormData();
    do {
        properties = Object.getOwnPropertyNames( inputObject );
        for (var o in properties) {
            detailedObject[properties[o]] = inputObject[properties[o]];
            if(!formData.get(properties[o]))
                formData.append( properties[o], inputObject[properties[o]])
        }
    } while ( inputObject = Object.getPrototypeOf( inputObject ) );

    return formData;
}

export const getUrl = (data) => {
    var body = getDetailedObject(data)
    return async dispatch => {
        try{
            const payload = await api.post("/transaction/process", data);
            dispatch({
                type: GET_URL,
                payload,
            })
        }catch(e){
            dispatch({
                type: GET_URL,
                payload: {
                    Success: false,
                    Message: "Error processing transaction",
                    timestamps: +(new Date())
                }
            })
        }
       
    }
}

export const getTransactionReport = (token, data={}) => {
    
    return async dispatch => {
        try{
            var formData = new FormData();
            formData.append('orderId', data.orderId);
            formData.append('cardName', data.cardName);
            const payload = await api.post(`/transaction/transactionreport/${token}`, formData);
            dispatch({
                type: GET_TRANSACTION_REPORT,
                payload,
            })
        }catch(e){
            dispatch({
                type: GET_TRANSACTION_REPORT,
                payload: {
                    Success: false,
                    Message: "Error processing transaction",
                    timestamps: +(new Date())
                }
            })
        }
       
    }
}

export const saveCard = (data) => {
    
    return async dispatch => {
        try{
            const payload = await api.post(`/transaction/savecard/`, data);
            dispatch({
                type: SAVE_CARD,
                payload,
            })
        }catch(e){
            dispatch({
                type: SAVE_CARD,
                payload: {
                    Success: false,
                    Message: "Error processing transaction",
                    timestamps: +(new Date())
                }
            })
        }
       
    }
}


export const getCards = () => {
    return async dispatch => {
        try{
            const payload = await api.get("/home/mycards");
            setLocalStorage("cards",  payload.data, null)
            dispatch({
                type: SAVE_CARD,
                payload,
            })
        }catch(e){
            console.log(e)
            dispatch({
                type: SAVE_CARD,
                payload: {
                    Success: false,
                    Message: "Error getting cards",
                    timestamps: +(new Date())
                }
            })
        }

    }
}