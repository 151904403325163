import { isEmpty } from "./util";

export function invalidMinLength(value, min) {
    return `${value}`.length < min;
  }

  export function invalidMaxLength(value, max) {
    return `${value}`.length > max;
  }
  export function invalidExactLength(value, max) {
    return `${value}`.length !== max;
  }

  export function invalidAllowExts(value, extensions) {
    return !Array.isArray(extensions) || extensions.indexOf(value) === -1;
  }

  export function invalidEmail(value) {
    return !(/[a-zA-Z0-9._#~&-]+@[a-zA-Z0-9._#~&-]+\.[a-z]{2,}/.test(value));
  }

  export function invalidCompare(value, compareValue) {
    return value !== compareValue;
  }

  export const invalidMin = (value, minimum) => {
    return value < minimum;
  };

  export const invalidMax = (value, maximum) => {
    return value > maximum;
  };

  export function invalidCard(value){
    /*
      // For Complete Card Validation
      const validCards = {
        americaExpress: /^(?:3)([47])(?:[0-9]{13})$/,
        visa: /^(4)([0-9]{12})(?:[0-9]{3})?$/,
        mastercard: /^(?:5)([1-5])(?:[0-9]{14})$/,
        discover: /^(?:6)(011|5[0-9][0-9])(?:[0-9]{12})$/,
        dinersClub: /^3(0[0-5]|[68][0-9])(?:[0-9]{11})$/,
        jcb: /^(2131|1800|35\d{3})(?:\d{11})$/,
        verve: /^(506)(?:0|1)(?:[0-9]{15})$/,
      };
    */
    // To check the first unique card
    const validShortCards = {
      americaExpress: /^3[47]/,
      visa: /^4/,
      mastercard: /^5[1-5]/,
      discover: /^(6011|5)/,
      dinersClub: /^3(0[0-5]|[68])/,
      jcb: /^(2131|1800|35)/,
      verve: /^506(0|1)/,
    };

    let cardType = 'credit';

    const str = value.replace(/ /g, '');
    // eslint-disable-next-line no-unused-vars
    for ( let key in validShortCards){
      if ((new RegExp(validShortCards[key])).test(str)){
          cardType = key === 'discover' && validShortCards.verve.test(str) ? 'verve' :  key;
        break;
      }
    }
    return {status: cardLengthValid(cardType, str), cardType };
  }

  function cardLengthValid(card, value){
    switch (card){
      case 'visa':
          return [13, 16].indexOf(value.length) === -1;
      case 'mastercard':
          return [16].indexOf(value.length) === -1;
      case 'americaExpress':
          return [15].indexOf(value.length) === -1;
      case 'discover':
          const lendis = value.indexOf('5') === 0 ? 15 : 16;
          return [lendis].indexOf(value.length) === -1;
      case 'dinersClub':
          return [14].indexOf(value.length) === -1;
      case 'jcb':
          const lenjcb = value.indexOf('35') === 0 ? 16 : 15;
          return [lenjcb].indexOf(value.length) === -1;
      case 'verve':
          return [19].indexOf(value.length) === -1;
      default:
          return true;
    }
  }

  export function setOfRules(rule, value, attrs = ""){
    let message;
    switch(rule){
      case "required":
        message = isEmpty(value, true) ? "field is required" : null;
      break;
      case "min_length":
        message = invalidMinLength(value, parseInt(attrs, 10)) ? `field characters can not be less than ${attrs} characters` : null;
      break;
      case "max_length":
        message = invalidMaxLength(value, parseInt(attrs, 10)) ? `field characters can not be more than ${attrs} characters` : null;
      break;
      case "exact_length":
        message = invalidExactLength(value, parseInt(attrs, 10)) ? `field characters can only ${attrs} characters` : null;
      break;
      case "min":
          message = invalidMin(parseFloat(value), parseFloat(attrs)) ? `field can not be less than ${attrs}` : null;
      break
      case "max":
          const [att, mg] = attrs.split(",");

          const msgMax = !isEmpty(mg) ? mg : `field can not be greater than ${att}`

          message = invalidMax(parseFloat(value), parseFloat(att)) ? msgMax : null;
      break
      case "date":
        message = !(value instanceof Date) ? "field must be date" : null;
      break;
      case "date_diff":
        const [current, threshold, msg] = attrs.split(",");
        message = parseInt(current, 10) < parseInt(threshold, 10) ? msg : null;
      break;
      case "email":
        message = invalidEmail(value) ? "field does not have a valid email" : null;
        break;
      case "yearRange":
        const [min, max] = attrs.split(",");
        const now = new Date();
        const diff =  now.getFullYear() - value.getFullYear();
        
        message = diff < parseInt(min, 10) || diff > parseInt(max, 10) ? `field must be between ${min} and ${max}` : null;
        break;
      case "regex":
          const regex = new RegExp(attrs);
          
          message = !regex.test(value) ? 'field has invalid format': null;
      break;
      case "enum":
          const regexPrep = attrs.replace(/,/g, "|");
          const contain = new RegExp(regexPrep);
          message = !contain.test(value) ? `field can only contain ${attrs}`: null;
        break;
      case "contain":
          const [regexType, name] = attrs.split(",")
          const containSpecial = new RegExp(`[${regexType}]`);
          message = !containSpecial.test(value) ? `field must contain atleast one ${name}`: null;
        break;
      case "match":
          const [field, compare] = attrs.split(",")
          message = value !== compare ? `field must be the same as ${field}` : null;
          break;
      case "ng_phone":
          message = !(/[0-9]{11}/.test(value)) ? "field is not valid phone number" : null;
          break;
      
      case 'is_number':
      case 'is_numeric':
          message = isNaN(value) ? 'feild can only be a number' : null;
          break;
      default:
        throw new Error(`${rule} does not exist`);
    }
    return message;
  }

  export function validate(rules, value){
    let message = null;
    if (rules == null)
        return null;

    for(let rule of rules){
      const [type, attrs] = rule.split(":");
      if(type === "validateIfNotEmpty"){
        message = null;
        if(isEmpty(value, true)){
          break;
        }
        continue;
      }
      message = setOfRules(type, value, attrs);
      if(!isEmpty(message, true)){
        break;
      }
    }
    return message;
    
  }
