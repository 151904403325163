
import React from "react";
import PropTypes from "prop-types";

const BillPayment = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="31" height="40" viewBox="0 0 31 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.4402 1.03452C28.7707 0.726178 27.9819 0.846068 27.4343 1.3393L24.8347 3.6411L21.2979 0.477406C20.5874 -0.159135 19.5117 -0.159135 18.8011 0.477406L15.2673 3.6381L11.7326 0.477406C11.0219 -0.159135 9.94605 -0.159135 9.23532 0.477406L5.69763 3.6411L3.09564 1.3394C2.32788 0.65696 1.15221 0.726178 0.46977 1.49394C0.164238 1.83769 -0.00313996 2.2825 4.4621e05 2.74239V37.2576c0.00604354 38.2863 0.822977 39.1252 1.85169 39.1313C2.31167 39.134 2.75649 38.9663 3.10033 38.6607L5.69988 36.3589L9.23664 39.5226C9.94717 40.1591 11.0229 40.1591 11.7334 39.5226L15.2673 36.3615L18.8017 39.5222C19.5124 40.1587 20.5883 40.1587 21.299 39.5222L24.8367 36.3586L27.4387 38.6603C28.2063 39.3429 29.382 39.274 30.0645 38.5063C30.3703 38.1625 30.5377 37.7174 30.5345 37.2572V2.74239C30.5438 2.00554 30.1134 1.33396 29.4402 1.03452ZM26.0739 34.9555C25.3633 34.326 24.2936 34.3288 23.5863 34.9622L20.0497 38.1258L16.5157 34.9651C15.8051 34.3285 14.7293 34.3285 14.0187 34.9651L10.4843 38.1258L6.94617 34.9622C6.24163 34.3289 5.17386 34.326 4.46586 34.9554L1.87333 37.2566V32.2638L1.86396 2.74286L4.4607 5.04409C5.17133 5.67361 6.24098 5.6708 6.94823 5.03744L10.4849 1.87384L14.0188 5.03454C14.7295 5.67108 15.8053 5.67108 16.5159 5.03454L20.0502 1.87384L23.5884 5.03744C24.293 5.67071 25.3607 5.67361 26.0687 5.04419L28.6613 2.74239V26.4686L28.6706 37.2568L26.0739 34.9555Z" fill={color}/>
<path d="M24.1459 19.0632H6.38855C5.87124 19.0632 5.4519 19.4826 5.4519 19.9999C5.4519 20.5172 5.87124 20.9365 6.38855 20.9365H24.1459C24.6633 20.9365 25.0826 20.5172 25.0826 19.9999C25.0826 19.4826 24.6632 19.0632 24.1459 19.0632Z" fill={color}/>
<path d="M15.7162 13.4434H6.38855C5.87124 13.4434 5.4519 13.8627 5.4519 14.38C5.4519 14.8973 5.87124 15.3166 6.38855 15.3166H15.7162C16.2335 15.3166 16.6528 14.8973 16.6528 14.38C16.6528 13.8627 16.2334 13.4434 15.7162 13.4434Z" fill={color}/>
<path d="M24.1459 24.6831H6.38855C5.87124 24.6831 5.4519 25.1024 5.4519 25.6197C5.4519 26.1371 5.87124 26.5564 6.38855 26.5564H24.1459C24.6633 26.5564 25.0826 26.1371 25.0826 25.6197C25.0826 25.1024 24.6632 24.6831 24.1459 24.6831Z" fill={color}/>
</svg>

    )
}

BillPayment.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

BillPayment.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default BillPayment
    