export const theme = {
  color:{
    primary: '#0193dd',
    secondary: '#e67818',
    tertiary: '#494f5f',
    grey: '#dcdcdc',
    lightSecondary: "#e678185e",
    lightPrimary: "#00aee9",
    textColor: "#ffffff",
  },
  zoom: 0.85,
  alertSuccess: {
    color: '#6fcf97',
    bgColor: '#eefff5',
  },
  alertError: {
    color: '#ed6161',
    bgColor: '#fff0f0',
  },
  alertInfo: {
    color: '#2196f3',
    bgColor: '#dbefff',
  },
  ff: {
    arial: "Arial, Helvetica, sans-serif",
  },
  flexible: () => ({
    display: 'flex',
  }),
  breakpoints: {
    esDown: '@media only screen and (max-width: 360px)',
    esUp: '@media only screen and (min-width: 360px)',
    esXs: '@media only screen and (min-width: 361px) and (max-width:479px)',
    xsDown: '@media only screen and (max-width: 480px)',
    xsUp: '@media only screen and (min-width: 480px)',
    xsSm: '@media only screen and (min-width: 481px) and (max-width: 599px)',
    smDown: '@media only screen and (max-width: 600px)',
    smUp: '@media only screen and (min-width: 600px)',
    smMd: '@media only screen and (min-width: 601px) and (max-width: 767px)',
    mdDown: '@media only screen and (max-width: 768px)',
    mdUp: '@media only screen and (min-width: 768px)',
    mdLg: '@media only screen and (min-width: 769px) and (max-width: 991px)',
    lgDown: '@media only screen and (max-width: 992px)',
    lgUp: '@media only screen and (min-width: 992px)',
    lgXl: '@media only screen and (min-width: 993px) and (max-width: 1199px)',
    mdXl: '@media only screen and (min-width: 769px) and (max-width: 1199px)',
    xlDown: '@media only screen and (max-width: 1200px)',
    xlUp: '@media only screen and (min-width: 1200px)',
    xxlDown: '@media only screen and (max-width: 1400px)',
    xxlUp: '@media only screen and (min-width: 1400px)',
  },
};
