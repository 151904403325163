
import React from "react";
import PropTypes from "prop-types";

const PasswordChange = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.3516 21.4453C15.9988 21.4453 16.5234 20.9206 16.5234 20.2734C16.5234 19.6262 15.9988 19.1016 15.3516 19.1016C14.7044 19.1016 14.1797 19.6262 14.1797 20.2734C14.1797 20.9206 14.7044 21.4453 15.3516 21.4453Z" fill={color}/>
<path d="M19.2773 27.6562H4.6875C3.39516 27.6562 2.34375 26.6048 2.34375 25.3124V15.7031C2.34375 14.4107 3.39516 13.3593 4.6875 13.3593H21.5625C21.8293 13.3593 22.041 13.3829 22.2098 13.4312C22.8319 13.6095 23.4808 13.2496 23.6591 12.6274C23.8373 12.0053 23.4775 11.3564 22.8553 11.1781C22.4737 11.0688 22.0508 11.0156 21.5625 11.0156H20.1541V6.88277C20.1541 3.0876 16.9999 0 13.1228 0C9.2458 0 6.09158 3.0876 6.09158 6.88277V11.0156H4.6875C2.10281 11.0156 0 13.1184 0 15.7031V25.3125C0 27.8972 2.10281 30 4.6875 30H19.2773C19.9246 30 20.4492 29.4754 20.4492 28.8281C20.4492 28.1809 19.9246 27.6562 19.2773 27.6562ZM8.43533 6.88277C8.43533 4.37994 10.5381 2.34375 13.1228 2.34375C15.7075 2.34375 17.8103 4.37994 17.8103 6.88277V11.0156H8.43533V6.88277Z" fill={color}/>
<path d="M10.957 21.4453C11.6042 21.4453 12.1289 20.9206 12.1289 20.2734C12.1289 19.6262 11.6042 19.1016 10.957 19.1016C10.3098 19.1016 9.78516 19.6262 9.78516 20.2734C9.78516 20.9206 10.3098 21.4453 10.957 21.4453Z" fill={color}/>
<path d="M28.1316 19.4068C28.1059 17.134 26.249 15.293 23.9702 15.293C21.6756 15.293 19.8086 17.1599 19.8086 19.4546C19.8086 20.1018 20.3332 20.6265 20.9805 20.6265C21.6277 20.6265 22.1523 20.1018 22.1523 19.4546C22.1523 18.4522 22.9679 17.6367 23.9703 17.6367C24.9727 17.6367 25.7881 18.4522 25.7881 19.4546C25.7881 19.4653 25.7883 19.4759 25.7886 19.4864C25.7765 20.204 25.3454 20.8464 24.6832 21.1291C23.5383 21.618 22.7984 22.7473 22.7984 24.0061V25.0195C22.7984 25.6668 23.323 26.1914 23.9703 26.1914C24.6175 26.1914 25.1421 25.6668 25.1421 25.0195V24.0061C25.1421 23.6875 25.3233 23.4043 25.6036 23.2846C27.1403 22.6286 28.133 21.1252 28.1326 19.4544C28.1326 19.4384 28.1322 19.4226 28.1316 19.4068Z" fill={color}/>
<path d="M23.9648 30C24.6121 30 25.1367 29.4753 25.1367 28.8281C25.1367 28.1809 24.6121 27.6562 23.9648 27.6562C23.3176 27.6562 22.793 28.1809 22.793 28.8281C22.793 29.4753 23.3176 30 23.9648 30Z" fill={color}/>
<path d="M6.5625 21.4453C7.20971 21.4453 7.73438 20.9206 7.73438 20.2734C7.73438 19.6262 7.20971 19.1016 6.5625 19.1016C5.91529 19.1016 5.39062 19.6262 5.39062 20.2734C5.39062 20.9206 5.91529 21.4453 6.5625 21.4453Z" fill={color}/>
</svg>

    )
}

PasswordChange.defaultProps = {
    color: '#01122B',
    fontSize: 15
}

PasswordChange.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default PasswordChange
    