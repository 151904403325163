
import React from "react";
import PropTypes from "prop-types";

const PhoneCall = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M1.11111 0H5C5.61111 0 6.11111 0.5 6.11111 1.11111C6.11111 2.5 6.33333 3.83333 6.74444 5.07778C6.86667 5.46667 6.77778 5.9 6.46667 6.21111L4.02222 8.65556C5.62222 11.8 8.2 14.3667 11.3444 15.9778L13.7889 13.5333C14.0111 13.3222 14.2889 13.2111 14.5778 13.2111C14.6889 13.2111 14.8111 13.2222 14.9222 13.2667C16.1667 13.6778 17.5111 13.9 18.8889 13.9C19.5 13.9 20 14.4 20 15.0111V18.8889C20 19.5 19.5 20 18.8889 20C8.45556 20 0 11.5444 0 1.11111C0 0.5 0.5 0 1.11111 0ZM3.93333 2.22222C4 3.21111 4.16667 4.17778 4.43333 5.1L3.1 6.43333C2.64444 5.1 2.35556 3.68889 2.25556 2.22222H3.93333ZM14.8889 15.5778C15.8333 15.8444 16.8 16.0111 17.7778 16.0778V17.7333C16.3111 17.6333 14.9 17.3444 13.5556 16.9L14.8889 15.5778Z" fill={color}/>
</svg>

    )
}

PhoneCall.defaultProps = {
    color: '#E7792B',
    fontSize: 15
}

PhoneCall.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default PhoneCall
    