
import React from "react";
import PropTypes from "prop-types";

const Linkedin = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.3997 0C5.13495 0 0.899658 4.26471 0.899658 9.5C0.899658 14.7647 5.16436 19 10.3997 19C15.6644 19 19.8997 14.7353 19.8997 9.5C19.9291 4.26471 15.6644 0 10.3997 0ZM7.95848 14.2059H5.87025V7.52941H7.95848V14.2059ZM6.92907 6.58823C6.2526 6.58823 5.72319 6.05882 5.72319 5.38235C5.72319 4.70588 6.2526 4.17647 6.92907 4.17647C7.60554 4.17647 8.13495 4.70588 8.13495 5.38235C8.13495 6.05882 7.57613 6.58823 6.92907 6.58823ZM15.7526 14.2059H13.6644V10.9412C13.6644 10.1765 13.635 9.17647 12.5761 9.17647C11.4879 9.17647 11.3408 10.0294 11.3408 10.8824V14.1765H9.2526V7.5H11.2526V8.41177H11.282C11.5467 7.88235 12.2526 7.32353 13.2526 7.32353C15.3702 7.32353 15.7526 8.70588 15.7526 10.5V14.2059Z" fill={color}/>
</svg>

    )
}

Linkedin.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

Linkedin.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Linkedin
    