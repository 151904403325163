import React, { Component } from 'react';
import PropTypes from "prop-types";
import { styles } from "./style";
import withStyle from '../jss';
import { classNames, isEmpty, groupBy } from '../../helper';

class Select extends Component {
    constructor(props) {
        super(props);
        const { items, classes } = props;
        const validItems = !isEmpty(items) && Array.isArray(items) ? items : [];

        this.state = { 
            search: "",
            active: false,
            removeDropdownItems: false,
            items: [...validItems],
            innerItems: [...validItems],
            position: "down",
            currentItem: null,
         }
         

         this.selectedClass = classes.selectWrapper;
    }

    componentDidMount(){

        const { defaultValue, valKey, labelKey, disableOutSideClick} = this.props;
        const {innerItems} = this.state;

        const item = innerItems.find(datum => `${datum[valKey]}`.toLowerCase() === `${defaultValue}`.toLowerCase());

        // const currentItem = isEmpty(defaultValue) || isEmpty(item) ? null : item;

        this.setState({
            search: isEmpty(defaultValue) || isEmpty(item) ? "" : item[labelKey],
            currentItem: isEmpty(defaultValue) || isEmpty(item) ? null : item,
        });

        // if(!disableOutSideClick){
        //     document.addEventListener("click", this.onClickOutSide);
        // }
    }


    componentDidUpdate(prevProps){
        if(!isEmpty(this.props.defaultValue) && this.props.defaultValue !== prevProps.defaultValue){
            const { defaultValue, items, valKey, labelKey} = this.props;

            const item = items.find(datum => `${datum[valKey]}`.toLowerCase() === `${defaultValue}`.toLowerCase());

            this.setState({
                items: [...items],
                search: isEmpty(defaultValue) || isEmpty(item) ? "" : item[labelKey],
                currentItem: isEmpty(defaultValue) || isEmpty(item) ? null : item,
            });
        }
    }





    onClickOutSide = (e) => {
        const {onChange, labelKey} = this.props;
        e.preventDefault();
        if(!e.target.closest(`.${this.selectedClass}`)){
            
            if(onChange) onChange(this.state.currentItem);

            this.setState({
                active: false,
                innerItems: [...this.state.items],
                search: !isEmpty(this.state.currentItem, false, true) ? this.state.currentItem[labelKey] : "",
                removeDropdownItems: false
            });
            this.clearTimeoutClick = setTimeout(() => {
                this.setState({
                    removeDropdownItems: false
                });
            }, 500)
        }
    }

    onChangeHandler = (e) => {
        const {disabled, labelKey} = this.props;
        if(disabled) return;
        const { value } = e.target;
        const innerItems = this.state.items.filter( item => (
            `${item[labelKey]}`.toLowerCase().includes(`${value}`.toLowerCase().trim())
        ));
        this.setState({
            search: value,
            innerItems
        })
    }

    onClickHander = (e, data) => {
        const { onChange, labelKey } = this.props;
        const { items } = this.state;

        if(onChange) onChange(data);

        this.setState({
            active: false,
            innerItems: [...items],
            search: data[labelKey],
            currentItem: data
        })

        this.clearTimeout = setTimeout(() => {
            this.setState({
                removeDropdownItems: false
            })
        }, 500)
    }

    onActivateDropDown = (active) => {
        const {disabled, dropdownListHeight, disablePosition} = this.props;
        if(disabled) return;
        const { top, height} = this.selectWrapper.getBoundingClientRect();

        const up = disablePosition ? "down" : "up";

        const position = window.innerHeight - (top + height) >= dropdownListHeight ? "down" : up;

        this.setState({
            active,
            removeDropdownItems: true,
            position,
            search: "",
        })
    }

    renderSingleDropdownList = (items) => {
        const { labelKey, itemKey } = this.props;
        return items.map(item => (
            <div key={item[itemKey]} className="dropdown-item" onClick={(e) => this.onClickHander(e, item)}>
               {item[labelKey]}
            </div>
        ))
    }



    renderGroupByDropdownList = (items) => {
        const getGroupBy = groupBy(items);
        
        return Object.keys(getGroupBy).map(key => {
            return (
                <div key={key} className="group-by">
                    {
                        key !== "null" ? (
                            <div  className="dropdown-item">
                                {key}
                            </div>
                        ) : null
                    }
                     {
                        this.renderSingleDropdownList(getGroupBy[key])
                     }
                </div>
            )
        })
    }

    renderDropDownList = () => {
        const {innerItems} = this.state;
        return this.props.groupBy ? this.renderGroupByDropdownList(innerItems) : this.renderSingleDropdownList(innerItems); 
    }

    

    componentWillUnmount(){
        clearTimeout(this.clearTimeout);
        clearTimeout(this.clearTimeoutClick);
        document.removeEventListener("click", this.onClickOutSide);
    }

    render() { 
        const { 
            classes, label, required, autoComplete, placeholder, disabled,
            error, errorMessage, dropdownListHeight, showArrow, disablePosition,
            disablePositionTop
         } = this.props;

        const { 
            search, active, removeDropdownItems, position, innerItems
        } = this.state;
        
        return ( 
            <div className={classNames({
                [this.selectedClass]: true,
            })}>
                <div className="inner-select-wrapper" ref={el => this.selectWrapper = el}>
                    {
                        !isEmpty(label) ? (
                        <label className={classNames({
                            "is-required": required
                        })}>{label}</label>
                        ): null
                    }
                    <div className={classNames({
                        "input-value": true,
                        "disabled": disabled,
                        "showArrow": showArrow
                     })} onClick={(e) => this.onActivateDropDown(true)}>
                        <input 
                            name={label}
                            value={search} 
                            onChange={this.onChangeHandler} 
                            autoComplete={autoComplete ? 'on' : 'off'}
                            placeholder={placeholder}
                            
                        />
                    </div>
                    <div className={
                        classNames({
                            "dropdown-wrapper": true,
                            "active": active,
                            [position]: true,
                            "show-up-error": !isEmpty(error, true)
                        })
                    }
                        style={{
                            maxHeight: dropdownListHeight,
                            top: disablePosition ? disablePositionTop : null
                        }}
                    >
                        <div className={classNames({
                            "dropdown-list": true,
                            [classes.dropdownList]: true
                        })}
                        >
                            {
                                removeDropdownItems ? (
                                <>
                                
                                {
                                    this.renderDropDownList(innerItems)
                                }
                                </>
                                ): null
                            }
                        </div>
                    </div>
                    {!isEmpty(error, true) ? 
                    ( <small className={classNames({
                        [classes.errorMessage]: true,
                        [errorMessage]: !isEmpty(errorMessage) 
                    })}>{error}</small>)
                        : <small>&nbsp;</small>
                    }
                </div>
            </div>
         )
    }
}

Select.defaultProps = {
    required: false,
    disabled: false,
    items: [],
    valKey: "value",
    labelKey: "label",
    groupByProps: "Group",
    itemKey: "label",
    groupBy: false,
    disableOutSideClick: false,
    dropdownListHeight: 150,
    showArrow: true,
    disablePosition: false
}

Select.propTypes = {
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    items: PropTypes.array,
    disabled: PropTypes.bool,
    groupBy: PropTypes.bool,
    labelKey: PropTypes.string,
    valKey: PropTypes.string,
}
 
export default withStyle(styles)(Select);
