import { themeReducer } from './theme';
import { categoryReducer } from './category';
import { sidebarReducer } from './sidebar';
import { transactionReducer } from "./transaction";
import { authReducer } from "./auth";
import { paReducer } from "./pa";
import { authdataReducer } from "./authdata";
import { passwordChangeReducer } from "./passwordChange";
import { ewalletReducer } from "./ewallet";
import { contactReducer } from "./contact-us";

export const reducer = (
  {  theme, categories, activateSidebar, transaction,
    auth, pa, authdata, passwordChange, ewallet, contactUs
   },
  action,
) => ({
  theme: themeReducer(theme, action),
  categories: categoryReducer(categories, action),
  activateSidebar: sidebarReducer(activateSidebar, action),
  transaction: transactionReducer(transaction, action),
  auth: authReducer(auth, action),
  pa: paReducer(pa, action),
  authdata: authdataReducer(authdata, action),
  passwordChange: passwordChangeReducer(passwordChange, action),
  ewallet: ewalletReducer(ewallet, action),
  contactUs: contactReducer(contactUs, action),
});
