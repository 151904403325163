import addDays from "date-fns/addDays";
import differenceInDays from "date-fns/differenceInDays";
import addMonths from "date-fns/addMonths";
import addWeeks from "date-fns/addWeeks";
import isMonday from "date-fns/isMonday";
import isTuesday from "date-fns/isTuesday";
import isWednesday from "date-fns/isWednesday";
import isThursday from "date-fns/isThursday";
import isFriday from "date-fns/isFriday";
import isSaturday from "date-fns/isSaturday";
import isSunday from "date-fns/isSunday";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import toDate from "date-fns/toDate";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import format from "date-fns/format";
import getDaysInMonth from "date-fns/getDaysInMonth";
import addYears from "date-fns/addYears";

import { isEmpty } from "./util";

function getApprovedCategories(category){
    return (
        category.HideOnWeb === false
        && category.Merchants.length > 0
        && removeMerChantsWithNoProducts(category.Merchants)
        && category.Name !== "UPIR Test"
    )
}

function removeMerChantsWithNoProducts(merchants){
    return merchants.filter(checkMerchantProduct).length > 0
}



function getApprovedMerchants(merchant){
    return (
        merchant.HideOnWeb === false
        && checkMerchantProduct(merchant)
        // && merchant.Name !== "UPIR Test"
    )
}

function checkMerchantProduct(merchant){
    return (
        merchant.Products.length > 0
        || merchant.Url.indexOf("http") === 0
    )
}



function getApprovedProducts(product){
    return (
        product.HideOnWeb === false
        // && product.Products.length > 0
        // && product.Name !== "UPIR Test"
    )
}

export function getDataByProp(data, name, prop = "Name"){
    return Array.isArray(data) ? data.find(datum => datum[prop] === name) : null;

}

export function prepareSelectItems(data = [], label = "Name", key="Id"){
    return data.map(datum => ({
        label: datum[label],
        value: datum,
        key: datum[key]
    }))
}

export function getApprovedData(data, name="category"){
    let fnType;
    switch(name){
        case "merchant":
            fnType = getApprovedMerchants;
            break;
        case "product":
            fnType = getApprovedProducts;
            break;
        default:
                fnType = getApprovedCategories
    }
    return data.filter(fnType)
}

export const ordinate = (num) => {
    let ord;
    if(num >= 4 && num <= 19){
        ord = "th";
    }else{
        const mod = num % 10;
        switch(mod){
            case 1:
                ord = "st";
                break;
            case 2:
                ord = "nd";
                break;
            case 3:
                ord = "rd";
                break;
            default:
                ord = "th"
        }
    }

    return `${num}${ord}`;
}   

export const joiner = (arr) => {
    const newArr = [...arr];
    const numOfEl = newArr.length;
    if(numOfEl === 1){
        const [el] = newArr;
        return el;
    }

    const lastEl = newArr.pop();

    return `${newArr.join(", ")} and ${lastEl}`;

}

export const prepareStandardPriceForSelect = (prices) => {
    return prices.split(",").map(price => {
        const [value, label] = `${price}`.split("|");
        return ({
            label, value
        })
    })
}


export const prepareStandardCategoryForSelect = (objects) => {
    let cats = []
    if (objects === undefined)
        return cats;
    objects.map(obj => {

        cats.push({
            lable: obj.Name, value: obj.Name
        })
    })
    return cats;
}



export const prepareDataForSearchList = (data) => {
    const searchList = [];
    const numOfCategories = data.length;
    // const regex = new RegExp("://");
    for(let i = 0; i < numOfCategories; i++){
        const category = data[i];
        const merchants = category.Merchants
        const numOfMerchants = merchants.length;
        for(let j = 0; j < numOfMerchants; j++){
            const merchant = merchants[j];
            const products = merchant.Products;
            const numOfProducts = products.length;
            for(let k = 0; k < numOfProducts; k++){
                const product = products[k];
                searchList.push({
                    category: category.Name,
                    currentMerchant: merchant,
                    product,
                    Name: `${merchant.Name} - ${product.Name}`
                })
            }
        }
    }

    return searchList;
}



export const getCategoryByMerchant = (data) => {
    const merchantCat = {};
    const numOfCategories = data.length;
    // const regex = new RegExp("://");
    for(let i = 0; i < numOfCategories; i++){
        const category = data[i];
        const merchants = category.Merchants
        const numOfMerchants = merchants.length;

        for(let j = 0; j < numOfMerchants; j++){
            const merchant = merchants[j];
            merchantCat[merchant.Name] = {
                icon: category.Name, category: category.Name, 
                categoryIndex: i, merchantIndex: j
            };
        }
    }

    return merchantCat;
}

const weekDays = (days, date) => {
    const wkDays = [
        {
            day: "SU",
            dayFun: isSunday,
        }, 
        {
            day: "MO",
            dayFun: isMonday,
        }, 
        {
            day: "TU",
            dayFun: isTuesday,
        }, 
        {
            day: "WE",
            dayFun: isWednesday,
        }, 
        {
            day: "TH",
            dayFun: isThursday,
        }, 
        {
            day: "FR",
            dayFun: isFriday,
        }, 
        {
            day: "SA",
            dayFun: isSaturday,
        }
    ];
    let dt = null;
    for(let i = 0; i < 7; i++){
        const week = wkDays[i];
        if(days.indexOf(week.day) > -1 && week.dayFun(date)){
            dt = date;
        }
    }
    return dt;
}

export const getWeekDays = (startDate, endDate, arrayOfDays, time) => {
    const payload = [];

    for(let i = 0; i <= 6; i++){
        const nextDate = addDays(startDate, i);
        
        if( endDate instanceof Date && differenceInDays(endDate, nextDate) < 1) break;

        const currentDate = weekDays(arrayOfDays, nextDate);

        if(isEmpty(currentDate) ) continue;

        payload.push(`${format(nextDate, "yyyy-MM-dd")}T${format(time, "hh:mm:ss")}.000Z`);
    }

    return payload;

}

export const getMonthDays = (initStartDate, startDate, endDate, arrayOfDays, time) => {
    const payload = [];
    const numOfDays = arrayOfDays.length;
    const getMonthIndex = getMonth(startDate);
    const getYearIndex = getYear(startDate);
    const getMonthDays = getDaysInMonth(startDate);
    


    for(let i = 0; i < numOfDays; i++){
        const day = parseInt(arrayOfDays[i], 10);

        // if last day was selected day is -1
        const currentDate = day === -1 ? (
           lastDayOfMonth(new Date(getYearIndex, getMonthIndex, 1))
        ) : (
            toDate(new Date(getYearIndex, getMonthIndex, day))
        );

        // If the last day of a month is selected and the days of a particular month
        // is selected then ignore the last day
        // for examplr april is 30 days, if day 30 is selected and last day is also selected
        // ignore selected last day for april
        if(arrayOfDays.indexOf(getMonthDays) > -1 && day === -1) continue;

        // Ensure all dates are more that the start date
        if(differenceInDays(currentDate, initStartDate) < 1) continue;

        // Ensure the day of month picked exist in a month
        if(day > getMonthDays) continue;

        // If endDate is instance of Date then endType is date
        if( endDate instanceof Date && differenceInDays(endDate, currentDate) < 1) break;

        payload.push(`${format(currentDate, "yyyy-MM-dd")}T${format(time, "hh:mm:ss")}.000Z`);
    }

    return payload;

    

}

const getUpperLimit = (counter, arr) => {
    // If date has been selected
    return !Array.isArray(arr) || arr.length < 1 ? counter : Math.ceil(counter / arr.length) + 1;
}

export const paDateFormatter = (props) => {
    const {
        startDate, endDate, endType, counter,
        reoccuranceDateType, interval, attr,

    } = props

    let innerEndDate = endType === "date" ? endDate : getUpperLimit(counter, attr);

    const time = startDate;

    let addFunc;
    // let diffFunc;

    const lowerDateType = reoccuranceDateType.toLowerCase();

    switch(lowerDateType){
        case "daily":
            addFunc = addDays;
        break;
        case "weekly":
            addFunc = addWeeks;
        break;
        case "monthly":
            addFunc = addMonths;
        break;
        default:
            addFunc = addYears;

    }

    let payload = [];

    let i = 0;

    while(true){
        const currentStartDate = addFunc(startDate, i * interval);

        // If endType is date then when the day difference of endDate and currentDate is less than 1 the 
        // break 
        if(endType === "date" && differenceInDays(endDate, currentStartDate) < 1) break;

        // If dateType is weekly and specific days of the week are selected
        if(lowerDateType === "weekly" && Array.isArray(attr) && attr.length > 0){
            payload = payload.concat(getWeekDays(
                currentStartDate, 
                innerEndDate,
                attr,
                time
            ));

        }
        // If dateType is monthly and specific days of the month are selected
        else if(lowerDateType === "monthly" && Array.isArray(attr) && attr.length > 0){
            payload = payload.concat(
                getMonthDays(
                    startDate,
                    currentStartDate, 
                    innerEndDate,
                    attr,
                    time
                ))
        }else{
            payload = payload.concat([`${format(currentStartDate, "yyyy-MM-dd")}T${format(time, "hh:mm:ss")}.000Z`])
        }

        // If endType is count then break when number of items in payload exceed counter
        if(endType === "count" && payload.length >= counter) break;
        i++;

    }

    return endType === "date" ? payload : payload.splice(0, counter);

}
