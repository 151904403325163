import React from 'react';
import { classNames, isEmpty } from '../helper/util';
import withStyle from './jss';


const styles = theme => ({
    checkboxWrapper:{
        display: "flex",
        alignItems: "center",
        "& > span":{
            boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.55)",
            width: 30,
            height: 30,
            flex: "0 0 30px",
            overflow: "hidden",
            display: "inline-block",
            marginRight: 10,
            cursor: "pointer",
            transition: "all 0.5s",
            "& span":{
                display: "inline-block",
            },
            "& > span":{
                width: 15,
                height: 15,
                position: "relative",
                visibility: "hidden",
                opacity: 0,
                transition: "all 0.5s",
                transform: "rotateZ(50deg)",
                "& > span":{
                    position: "absolute",
                    backgroundColor: "#ffffff",
                    "&:first-child":{
                        width: 4,
                        height: 7.5
                    },
                    "&:last-child":{
                        width: 15,
                        height: 3

                    }

                }
            },
            "&.active":{
                backgroundColor: theme.color.primary,
                "& > span":{
                    visibility: "visible",
                    opacity: 1,
                    transform: "rotateX(225deg) rotateZ(62.5deg)",
                    left: "4.5px",
                    top: "4.5px",
                }
            },

        },
        "& p":{
            margin: 0,
        }
    }
})

const Checkbox = (props) => { 
        const { active, classes, label, onClick, className, labelHTML } = props;
        return ( 
            <div className={classNames({
                [classes.checkboxWrapper]: true,
                [className]: !isEmpty(className)
            })
                
                }>
                <span 
                    className={classNames({
                        active
                    })}
                    onClick={onClick}
                >
                    <span>
                        <span></span>
                        <span></span>
                    </span>
                </span>
                {
                    !isEmpty(label) ? (
                        <p>{label}</p>
                    ) : null
                }
                {
                    !isEmpty(labelHTML) ? (
                        labelHTML
                    ) : null
                }
            </div>
        )
}
 
export default withStyle(styles)(Checkbox);