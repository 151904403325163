import {
    AUTH_LOGIN,
    AUTH_REGISTER,
    AUTH_RESET_PASSWORD,
    AUTH_LOGOUT,
    AUTH_AUTHORIZED,
    AUTH_UPDATE_PASSWORD,
    AUTH_GET_PROFILE,
    AUTH_PASSWORD_CHANGE,
    AUTH_ACTIVATE_USER
} from "../constant";
import { API, isEmpty, removeLocalStorage, getLocalStorage, setLocalStorage } from "../../helper";

const api = new API();

export const onLogin = (data) => {
    return async dispatch => {
        try{

            const formData = new FormData();
            formData.append("username", data.username);
            formData.append("password", data.password);
            formData.append("cardName", data.cardName);
            formData.append("grant_type", "password");
            formData.append("transactionId", data.transactionId);

            let response = await fetch(`${process.env.REACT_APP_API_URL}/account/login`, {
                method: 'POST',
                body: formData
              });

            if(response.status !== 200){

                throw new Error("There was a issue verifying your credentials");
                
            }
            response = await response.json();


            if(!isEmpty(response.error, true)){
                response = {...response, Success: false, Messages:response.error};
            }else{
                response = {...response.Data, Success: true}
                setLocalStorage("auth", {data: response}, response.expires_in);
                api.proxyAPI = true;
                api.noAuth = false;
                const resp = await api.post("/mobile/getprofile", {});

                // console.log(resp);

                if(isEmpty(resp.Messages)){
                    const timestamp = resp.timestamp;

                    delete resp.timestamps;
                    
                    
                    setLocalStorage("auth", {data: {...response, getProfile: {...resp}}}, response.expires_in);

                    response = {...response, timestamp };
                    
                    const cards = await api.get("/home/mycards");
                    setLocalStorage("cards",  cards.data, response.expires_in)
                }else{
                    removeLocalStorage("auth");

                    response = {...resp};
                    
                }

            }

            return dispatch({
                type: AUTH_LOGIN,
                payload: response
            });

        }catch(e){
            return dispatch({
                type: AUTH_LOGIN,
                payload: {
                    Success: false,
                    Messages: e.message.includes("verifying") ? e.message : "Something is wrong",
                    timestamps: +(new Date())
                }
            })
        }
    }
}

export const onActivateConfirmation = (token, data={}) => {
    return async dispatch => {
        try{
            let response = await api.post(`/confirmactivation/${token}`, data);

            return dispatch({
                type: AUTH_ACTIVATE_USER,
                payload: response
            });

        }catch(e){

            console.log(e);
            return dispatch({
                type: AUTH_RESET_PASSWORD,
                payload: {
                    Success: false,
                    Messages: "Something is wrong",
                    timestamps: +(new Date())
                }
            })
        }
    }
}

export const onLogout = (data = {}) => {
    return async dispatch => {
        try{
            removeLocalStorage("auth");
            let response = await api.post("/logout", data);

            if(isEmpty(response, true)) throw new Error("Response is Empty");

            const [Messages]= Object.keys(response.Messages)
            response = {...response, Messages};

            dispatch({
                type: AUTH_LOGIN,
                payload: null,
            });

            return dispatch({
                type: AUTH_LOGOUT,
                payload: response
            });

        }catch(e){

            return dispatch({
                type: AUTH_LOGOUT,
                payload: {
                    Success: false,
                    Messages: "Something is wrong",
                    timestamps: +(new Date())
                }
        });
     }
    }
}

export const onRegister = (route, data) => {
    return async dispatch => {
        try{
            const api2 = new API();

            if(route === "/saveprofile"){
                api2.noAuth = false;
                api2.proxyAPI = true;
            }
            // where route can be "/register" or "/saveprofile"
            let response = await api2.post(route, data);

            return dispatch({
                type: AUTH_REGISTER,
                payload: response
            });

        }catch(e){
            return dispatch({
                type: AUTH_REGISTER,
                payload: {
                    Success: false,
                    Messages: "Something is wrong",
                    timestamps: +(new Date())
                }
            })
        }
    }
}

export const onPasswordReset = (data = {}) => {
    return async dispatch => {
        try{
            let response = await api.post("/reset", data);

            return dispatch({
                type: AUTH_RESET_PASSWORD,
                payload: response
            });

        }catch(e){
            console.log(e);
            return dispatch({
                type: AUTH_RESET_PASSWORD,
                payload: {
                    Success: false,
                    Messages: "Something is wrong",
                    timestamps: +(new Date())
                }
            })
        }
    }
}

export const onActivateAccount = (data = {}) => {
    return async dispatch => {
        try{
            let response = await api.get("/activate/"+data);

            return dispatch({
                type: AUTH_ACTIVATE_USER,
                payload: response
            });

        }catch(e){
            console.log(e);
            return dispatch({
                type: AUTH_ACTIVATE_USER,
                payload: {
                    Success: false,
                    Messages: "Something is wrong",
                    timestamps: +(new Date())
                }
            })
        }
    }
}

export const onPasswordChange = (data = {}) => {
    return async dispatch => {
        try{
            let response = await api.post("/resetpassword", data);

            return dispatch({
                type: AUTH_PASSWORD_CHANGE,
                payload: response
            });

        }catch(e){
            console.log(e);
            return dispatch({
                type: AUTH_PASSWORD_CHANGE,
                payload: {
                    Success: false,
                    Messages: "Something is wrong",
                    timestamps: +(new Date())
                }
            })
        }
    }
}

export const onUpdatePassword = (data) => {
    return async dispatch => {
        try{
            let response = await api.post("/password", data);
            

            if(response.Success){
                removeLocalStorage("auth");
                setLocalStorage("passwordUpdate", response.Messages)
                await api.post("/logout", data);
            }

            return dispatch({
                type: AUTH_UPDATE_PASSWORD,
                payload: response
            });

        }catch(e){
            console.log(e);
            return dispatch({
                type: AUTH_UPDATE_PASSWORD,
                payload: {
                    Success: false,
                    Messages: "Something is wrong",
                    timestamps: +(new Date())
                }
            })
        }
    }
}

export const onGetProfile = data => {
    return async dispatch => {
        try{
            const api2 = new API();
            api2.noAuth = false;
            api2.proxyAPI = true;
            let response = await api2.post("/home/getprofile", data);

            if(isEmpty(response.Success)){
                response = {...response, Success: true};
            }

            return dispatch({
                type: AUTH_GET_PROFILE,
                payload: response
            });

        }catch(e){
            console.log(e);
            return dispatch({
                type: AUTH_GET_PROFILE,
                payload: {
                    Success: false,
                    Messages: "Something is wrong",
                    timestamps: +(new Date())
                }
            })
        }
    }
}

export const onAuth = ()  => ({
    type: AUTH_AUTHORIZED,
    payload: getLocalStorage("auth")
})
