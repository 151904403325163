export const styles = theme => ({
    footer:{
        backgroundColor: "#ffffff",
        "&.home":{
            borderTop: "2px solid #e7792c",
        },
        "& .footer-wrapper":{
            [theme.breakpoints.xlDown]:{

                maxWidth: 960,
                margin: "auto",
            },
            [theme.breakpoints.xlUp]:{
                marginLeft: "10%",
                marginRight: "10%",
            },
            paddingTop: 30,
            [theme.breakpoints.mdLg]:{
                marginLeft: 20,
                marginRight: 20,
            },
            "& .top-footer-wrapper":{
                display: "flex",
                [theme.breakpoints.mdDown]:{
                    // flexDirection: "row",
                    alignItems: "flex-start",
                    padding: 20,
                    flexWrap: "wrap"
                },
                "& > .box":{
                    flex: 1,
                    [theme.breakpoints.mdDown]:{
                        flex: "0 0 50%",
                        textAlign: "left",
                        marginBottom: 40,
                    },

                    "& .social-media":{
                        backgroundColor: "transparent",
                        padding: 0,
                        width: "unset",
                        boxShadow: "unset",
                        "&:not(:last-child)":{
                            marginRight: 10,
                        }
                    },  
                    "&:first-child":{
                        "& img":{
                            height: 40,
                            width: "auto",
                            display: "inline-block",
                            marginBottom: 10,
                        },
                        "& p":{
                            margin: 0,
                            fontSize: "1em",
                            paddingRight: 20,
                            textAlign: "justify",
                            color: "#01122B",
                            [theme.breakpoints.mdDown]:{
                                // textAlign: "center",
                            },
                        },
                        "& .social-media-icons":{
                            display: "flex",
                            marginTop: 10,
                            [theme.breakpoints.mdDown]:{
                                justifyContent: "flex-start",
                            },
                            "& img":{
                                display: "inline-block",
                                marginRight: 7.5,
                                height: 19,
                                width: 19,
                            }
                        }
                    },
                    "& .menu-list":{
                        display: "flex",
                        flexDirection: "column",
                        "& .menu-item-header":{
                            color: "#01122B",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "1.2em",
                            lineHeight: "100%",
                            userSelect: "none",
                            margin: 0,
                            marginBottom: 20,
                        },
                        "& .menu-item":{
                            fontSize: "1em",
                            lineHeight: "156%",
                            textDecoration: "none",
                            color: "#3D474B",
                            marginBottom: 16,
                        }
                    }
                }
            },
            "& .bottom-footer":{
                borderTop: "1px solid #D3D3D3",
                maxWidth: 960,
                margin: "auto",
                paddingTop: 15,
                paddingBottom: 15,
                "& p":{
                    fontSize: "0.8em",
                    margin: 0,
                    [theme.breakpoints.mdDown]:{
                        textAlign: "center",
                    }
                }
            }
        }
    }
})