
import React from "react";
import PropTypes from "prop-types";

const UserProfile = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M60 0C26.9159 0 0 26.9159 0 60C0 93.0841 26.9159 120 60 120C93.0841 120 120 93.0841 120 60C120 26.9159 93.0841 0 60 0ZM60 112.969C30.7929 112.969 7.03125 89.2071 7.03125 60C7.03125 30.7929 30.7929 7.03125 60 7.03125C89.2071 7.03125 112.969 30.7929 112.969 60C112.969 89.2071 89.2071 112.969 60 112.969Z" fill={color}/>
<path d="M60 63.2812C73.5696 63.2812 84.6094 52.2415 84.6094 38.6719C84.6094 25.1023 73.5696 14.0625 60 14.0625C46.4304 14.0625 35.3906 25.1023 35.3906 38.6719C35.3906 52.2415 46.4304 63.2812 60 63.2812ZM60 21.0938C69.6926 21.0938 77.5781 28.9793 77.5781 38.6719C77.5781 48.3645 69.6926 56.25 60 56.25C50.3074 56.25 42.4219 48.3645 42.4219 38.6719C42.4219 28.9793 50.3074 21.0938 60 21.0938Z" fill={color}/>
<path d="M99.527 78.6195C94.8552 73.3402 88.1364 70.3125 81.0937 70.3125H38.9062C31.8634 70.3125 25.1446 73.3402 20.4728 78.6195L18.8064 80.5027L20.05 82.6882C28.2102 97.0289 43.5182 105.938 59.9999 105.938C76.4816 105.938 91.7896 97.0289 99.9501 82.688L101.194 80.5024L99.527 78.6195ZM59.9999 98.9062C46.9368 98.9062 34.7434 92.2976 27.5664 81.4934C30.7192 78.8327 34.7333 77.3438 38.9062 77.3438H81.0937C85.2665 77.3438 89.2806 78.8327 92.4334 81.4934C85.2564 92.2976 73.063 98.9062 59.9999 98.9062Z" fill={color}/>
</svg>

    )
}

UserProfile.defaultProps = {
    color: '#219653',
    fontSize: 15
}

UserProfile.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default UserProfile
    