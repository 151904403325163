
import React from "react";
import PropTypes from "prop-types";

const BookHotels = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.5627 9.96289H7.28149C6.90395 9.96289 6.5979 10.2689 6.5979 10.6465V14.4746C6.5979 14.8522 6.90395 15.1582 7.28149 15.1582H10.5627C10.9403 15.1582 11.2463 14.8522 11.2463 14.4746V10.6465C11.2463 10.2689 10.9403 9.96289 10.5627 9.96289ZM9.87915 13.791H7.96509V11.3301H9.87915V13.791Z" fill={color}/>
<path d="M10.5627 17.6191H7.28149C6.90395 17.6191 6.5979 17.9252 6.5979 18.3027V22.1309C6.5979 22.5084 6.90395 22.8145 7.28149 22.8145H10.5627C10.9403 22.8145 11.2463 22.5084 11.2463 22.1309V18.3027C11.2463 17.9252 10.9403 17.6191 10.5627 17.6191ZM9.87915 21.4473H7.96509V18.9863H9.87915V21.4473Z" fill={color}/>
<path d="M10.5627 25.2754H7.28149C6.90395 25.2754 6.5979 25.5814 6.5979 25.959V29.7871C6.5979 30.1647 6.90395 30.4707 7.28149 30.4707H10.5627C10.9403 30.4707 11.2463 30.1647 11.2463 29.7871V25.959C11.2463 25.5814 10.9403 25.2754 10.5627 25.2754ZM9.87915 29.1035H7.96509V26.6426H9.87915V29.1035Z" fill={color}/>
<path d="M28.4028 25.2754H25.1216C24.744 25.2754 24.438 25.5814 24.438 25.959V29.7871C24.438 30.1647 24.744 30.4707 25.1216 30.4707H28.4028C28.7804 30.4707 29.0864 30.1647 29.0864 29.7871V25.959C29.0864 25.5814 28.7804 25.2754 28.4028 25.2754ZM27.7192 29.1035H25.8052V26.6426H27.7192V29.1035Z" fill={color}/>
<path d="M16.5093 9.96289H13.228C12.8505 9.96289 12.5444 10.2689 12.5444 10.6465V14.4746C12.5444 14.8522 12.8505 15.1582 13.228 15.1582H16.5093C16.8868 15.1582 17.1929 14.8522 17.1929 14.4746V10.6465C17.1929 10.2689 16.8868 9.96289 16.5093 9.96289ZM15.8257 13.791H13.9116V11.3301H15.8257V13.791Z" fill={color}/>
<path d="M22.4565 9.96289H19.1753C18.7977 9.96289 18.4917 10.2689 18.4917 10.6465V14.4746C18.4917 14.8522 18.7977 15.1582 19.1753 15.1582H22.4565C22.8341 15.1582 23.1401 14.8522 23.1401 14.4746V10.6465C23.1401 10.2689 22.8341 9.96289 22.4565 9.96289ZM21.7729 13.791H19.8589V11.3301H21.7729V13.791Z" fill={color}/>
<path d="M16.5093 17.6191H13.228C12.8505 17.6191 12.5444 17.9252 12.5444 18.3027V22.1309C12.5444 22.5084 12.8505 22.8145 13.228 22.8145H16.5093C16.8868 22.8145 17.1929 22.5084 17.1929 22.1309V18.3027C17.1929 17.9252 16.8868 17.6191 16.5093 17.6191ZM15.8257 21.4473H13.9116V18.9863H15.8257V21.4473Z" fill={color}/>
<path d="M22.4565 17.6191H19.1753C18.7977 17.6191 18.4917 17.9252 18.4917 18.3027V22.1309C18.4917 22.5084 18.7977 22.8145 19.1753 22.8145H22.4565C22.8341 22.8145 23.1401 22.5084 23.1401 22.1309V18.3027C23.1401 17.9252 22.8341 17.6191 22.4565 17.6191ZM21.7729 21.4473H19.8589V18.9863H21.7729V21.4473Z" fill={color}/>
<path d="M28.4028 9.96289H25.1216C24.744 9.96289 24.438 10.2689 24.438 10.6465V14.4746C24.438 14.8522 24.744 15.1582 25.1216 15.1582H28.4028C28.7804 15.1582 29.0864 14.8522 29.0864 14.4746V10.6465C29.0864 10.2689 28.7804 9.96289 28.4028 9.96289ZM27.7192 13.791H25.8052V11.3301H27.7192V13.791Z" fill={color}/>
<path d="M28.4028 17.6191H25.1216C24.744 17.6191 24.438 17.9252 24.438 18.3027V22.1309C24.438 22.5084 24.744 22.8145 25.1216 22.8145H28.4028C28.7804 22.8145 29.0864 22.5084 29.0864 22.1309V18.3027C29.0864 17.9252 28.7804 17.6191 28.4028 17.6191ZM27.7192 21.4473H25.8052V18.9863H27.7192V21.4473Z" fill={color}/>
<path d="M34.3164 33.6328H31.7522V7.11368C31.7522 6.73613 31.4461 6.43009 31.0686 6.43009H27.2745V3.96484C27.2745 3.5873 26.9685 3.28125 26.5909 3.28125H22.832V0.683594C22.832 0.306045 22.526 0 22.1484 0H12.8516C12.474 0 12.168 0.306045 12.168 0.683594V3.28125H8.40916C8.03161 3.28125 7.72557 3.5873 7.72557 3.96484V6.43009H3.93142C3.55387 6.43009 3.24782 6.73613 3.24782 7.11368V33.6328H0.683594C0.306045 33.6328 0 33.9389 0 34.3164C0 34.694 0.306045 35 0.683594 35H34.3164C34.694 35 35 34.694 35 34.3164C35 33.9389 34.694 33.6328 34.3164 33.6328ZM13.5352 1.36719H21.4648V3.28125H13.5352V1.36719ZM20.3711 33.6328H15.6412L20.3711 28.903V33.6328ZM20.3711 26.9737C20.3559 26.9865 20.3408 26.9997 20.3265 27.0141L14.6289 32.7116V29.8144L19.0137 25.4297H20.3711V26.9737ZM14.6289 27.8808V25.4297H17.0801L14.6289 27.8808ZM30.385 33.6328H25.8063H21.7383V25.4297H21.9775C22.3551 25.4297 22.6611 25.1236 22.6611 24.7461C22.6611 24.3685 22.3551 24.0625 21.9775 24.0625H13.0225C12.6449 24.0625 12.3389 24.3685 12.3389 24.7461C12.3389 25.1236 12.6449 25.4297 13.0225 25.4297H13.2617V33.6328H4.61501V7.79728H12.2034C12.5809 7.79728 12.887 7.49123 12.887 7.11368C12.887 6.73613 12.5809 6.43009 12.2034 6.43009H9.09275V4.64844H25.9073V6.43009H17.6042C17.2266 6.43009 16.9206 6.73613 16.9206 7.11368C16.9206 7.49123 17.2266 7.79728 17.6042 7.79728H30.385V33.6328Z" fill={color}/>
<path d="M15.3524 6.62998C15.2253 6.50283 15.0489 6.42969 14.8691 6.42969C14.6894 6.42969 14.513 6.50283 14.3858 6.62998C14.2587 6.75713 14.1855 6.9335 14.1855 7.11328C14.1855 7.29307 14.2587 7.46943 14.3858 7.59658C14.513 7.72441 14.6894 7.79688 14.8691 7.79688C15.0489 7.79688 15.2253 7.72441 15.3524 7.59658C15.4796 7.46943 15.5527 7.29307 15.5527 7.11328C15.5527 6.9335 15.4796 6.75713 15.3524 6.62998Z" fill={color}/>
</svg>

    )
}

BookHotels.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

BookHotels.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default BookHotels
    