export const styles = theme => ({
    modalWrapper: {
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        visibility: "hidden",
        opacity: 0,
        zIndex: -1,
        transition: "all 0.3s",
        overflow: "scroll",
       "-webkit-overflow-scrolling": "touch",
        "&.active":{
            zIndex: 5,
            visibility: "visible",
            opacity: 1,
            transition: "all 0.3s",
        },
    },
    innerModalWrapper: {
        position: "relative",
        top: "20px",
        width: "100%",
        maxWidth: "960px",
        minHeight: "150px",
        backgroundColor: "rgba(0, 0, 0,0.5)",
        transition: "all 0.5s",
        paddingTop: "70px",
        paddingRight: "20px",
        paddingLeft: "20px",
        marginBottom: "50px",
        boxShadow: "0px 0px 5px rgba(255, 255, 255, 0.6)",
        borderRadius: "50px",
        "&.active": {
            top: "50px",
            transition: "all 0.5s",
            "& > span":{
                height: "50px",
                right: "30px",
                fontSize: "1.8em",
                fontWeight: "bold",
                borderRadius: "110px",
                background: "#e67818",
                width: "50px",
                position: "absolute",
                top: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: "2px",
                color:" #ffffff",
                cursor: "pointer",
            },
            "& > div":{
    
            }
        }
    }
})