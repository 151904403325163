export const auth = {
    login: null,
    register: null,
    logout: null,
    auth: null,
    updatePassword: null,
    getProfile: null,
    passwordReset: null,
    passwordChange: null,
    activateUser: null,

}