import React, {useState} from 'react';
import withStyles from "./jss";
import Slider from "./Slider";
import { classNames, isEmpty } from "../helper/util";

const styles = {
    mainWraper: {
        display: "flex",
        flexDirection: "column",
    },
    sliderGroupWrapper: {
        display: "flex",
        flexWrap: "wrap",
        "&.vertical": {
            flexDirection: "column"
        }
    },
    sliderParagraph: {
        color: "#ffffff",
        fontWeight: "bolder",
    }
};


const SliderGroup = props => {
    const { classes, onChange, children, value} = props;
    const [label, setLabel] = useState(!isEmpty(value, true) ? value : null);
    return (
        <div className={classes.mainWraper}>
            {children}
            <div className={classNames({
            [classes.sliderGroupWrapper]: true,
                "vertical": props.vertical
            })}>

                {
                    props.data.map(datum => (
                        <Slider
                        key={datum.label}
                        active={label === datum.label}
                        onClick={() => {
                            // onChange(label !== datum.label ? datum : null);
                            onChange(datum);
                            setLabel(datum.label)
                        }}
                        >
                            <p className={classes.sliderParagraph}>{datum.label}</p>
                        </Slider>
                    ))
                }
            </div>
        </div>
    )
}

export default withStyles(styles)(SliderGroup)