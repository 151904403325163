
import React from "react";
import PropTypes from "prop-types";

const WaecResult = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="29" height="40" viewBox="0 0 29 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.5828 0H1.30005C0.582064 0 0 0.582064 0 1.30005V38.7C0 39.4179 0.582064 40 1.30005 40H27.5828C28.3008 40 28.8828 39.4179 28.8828 38.7V1.30005C28.8828 0.582064 28.3009 0 27.5828 0V0ZM26.2827 37.3999H2.60009V2.60009H26.2827V37.3999Z" fill={color}/>
<path d="M7.80167 30.1537C8.02912 30.1537 8.25949 30.0939 8.46872 29.9685C12.7075 27.4283 17.224 26.4514 22.0425 27.8615C22.7317 28.0636 23.4538 27.6681 23.6554 26.979C23.8571 26.2899 23.4619 25.5678 22.7728 25.366C17.5645 23.8419 12.3021 24.6399 7.13218 27.7382C6.00353 28.4146 6.48688 30.1537 7.80167 30.1537Z" fill={color}/>
<path d="M15.5862 11.3912C15.183 10.405 13.8134 10.4265 13.4178 11.3928L9.64542 20.8322C9.34362 21.535 9.87515 22.431 10.7197 22.431C11.1913 22.431 11.6241 22.1346 11.7959 21.6956L12.4538 20.0657H16.551L17.2081 21.6934C17.6667 22.8653 19.4419 22.5732 19.4419 21.2742C19.4418 20.9369 19.7297 21.7586 15.5862 11.3912ZM15.6169 17.7524H13.3891L14.5092 14.9455L15.6169 17.7524Z" fill={color}/>
</svg>

    )
}

WaecResult.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

WaecResult.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default WaecResult
    