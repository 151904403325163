
import React from "react";
import PropTypes from "prop-types";

const FoodAndDrink = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="43" height="40" viewBox="0 0 43 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M36.6919 21.205H33.7783L33.9323 17.9742C33.9497 17.608 33.8163 17.2506 33.5634 16.9854C33.3105 16.7203 32.9598 16.5701 32.5934 16.5701H3.10616C2.73956 16.5701 2.38904 16.7203 2.13615 16.9854C1.88326 17.2508 1.74989 17.608 1.76732 17.9742L2.36246 30.4587C2.50946 33.5439 4.3992 36.1398 7.05431 37.3191H1.3404C0.600277 37.3191 0 37.9194 0 38.6595C0 39.3996 0.600277 39.9999 1.3404 39.9999H34.5482C35.2883 39.9999 35.8886 39.3996 35.8886 38.6595C35.8886 37.9194 35.2883 37.3191 34.5482 37.3191H28.6455C30.7977 36.3629 32.4455 34.4759 33.0711 32.1516H36.6919C39.7098 32.1516 42.1652 29.6962 42.1652 26.6783C42.1652 23.6604 39.7098 21.205 36.6919 21.205ZM25.4276 35.3197H10.2722C7.47162 35.3197 5.1735 33.1283 5.04013 30.3309L4.51202 19.2505H31.1876L30.6597 30.3309C30.5263 33.1283 28.2282 35.3197 25.4276 35.3197ZM36.6919 29.4708H33.3845L33.6508 23.8829C33.6707 23.8838 33.6903 23.8858 33.7104 23.8858H36.6919C38.2318 23.8858 39.4844 25.1384 39.4844 26.6783C39.4844 28.2182 38.2318 29.4708 36.6919 29.4708Z" fill={color}/>
<path d="M13.9112 14.373C14.1252 14.4995 14.36 14.5598 14.5919 14.5598C15.0503 14.5598 15.4969 14.3243 15.7471 13.9012C16.1239 13.2639 15.9126 12.442 15.2752 12.0653C14.53 11.6248 14.0148 10.812 13.862 9.83579C13.7029 8.81931 13.9686 7.80686 14.572 7.12795C14.5773 7.12192 14.5827 7.11589 14.5878 7.10963C14.7337 6.9394 14.8796 6.77207 15.0241 6.60609C16.264 5.18213 17.5463 3.71015 17.9145 1.56774C18.04 0.838116 17.5501 0.144905 16.8207 0.0195776C16.0911 -0.10575 15.3979 0.38372 15.2726 1.11335C15.0315 2.51541 14.0912 3.5951 13.0026 4.84525C12.8558 5.01369 12.7079 5.1837 12.56 5.35616C11.4361 6.62686 10.9328 8.45517 11.2136 10.2497C11.4902 12.0204 12.4736 13.5232 13.9112 14.373Z" fill={color}/>
<path d="M18.0712 11.3462C18.5137 12.4858 19.3032 13.5295 20.2945 14.2851C20.5371 14.47 20.8226 14.5596 21.1061 14.5596C21.51 14.5596 21.9094 14.3778 22.173 14.0317C22.6218 13.4431 22.5084 12.602 21.9195 12.1532C21.3243 11.6994 20.8324 11.0518 20.5701 10.376C20.0476 9.03046 20.5427 7.68961 22.2257 5.89236C23.4189 4.61808 24.1816 3.11728 24.4312 1.55169C24.5475 0.820725 24.0496 0.133546 23.3184 0.0169306C22.5874 -0.0996843 21.9003 0.398498 21.7836 1.12969C21.6163 2.17945 21.1067 3.16531 20.269 4.06003C19.3899 4.99853 16.7154 7.85471 18.0712 11.3462Z" fill={color}/>
</svg>

    )
}

FoodAndDrink.defaultProps = {
    color: '#E7792B',
    fontSize: 15
}

FoodAndDrink.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default FoodAndDrink
    