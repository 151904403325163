export const styles = theme => ({
  tableWrapper: {
    '& *': {
      color: '#000000',
    },
    backgroundColor: '#ffffff',
    padding: 25,
    borderRadius: 3,
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.15)',
    [theme.breakpoints.mdDown]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  wrapperClassName: {
    '& > div:first-child': {},
    '&  p': {
      margin: '0px !important',
      marginLeft: '10px !important',
      fontSize: `${18 * theme.zoom}px`,
    },
    '& > p.special': {
      fontWeight: 550,
      margin: 0,
    },
    '& .inner': {
      display: 'flex',
      '&::after': {
        borderTopColor: '#000000 !important',
        top: 'calc(50% - 3px) !important',
      },
    },
    '& .custom-dropdown': {
      backgroundColor: '#f1f1f1 !important',
      top: `${20 * theme.zoom}px !important`,
      left: 0,
      '&::before': {
        top: '-20px',
        right: 'calc(100% - 40px) !important',
        borderBottomColor: '#f1f1f1 !important',
      },
      '&.active': {
        top: `${40 * theme.zoom}px !important`,
      },
    },
  },
  table: {
    borderSpacing: 0,
    width: '100%',
    borderCollapse: 'collapse',
    '& *': {
      color: '#000000',
    },
    [theme.breakpoints.mdDown]: {
      display: 'block',
    },
    '& thead': {
      [theme.breakpoints.mdDown]: {
        display: 'block',
      },
      '& tr': {
        [theme.breakpoints.mdDown]: {
          position: 'absolute',
          top: -999999,
          left: -999999,
        },
        '& th': {
          position: "relative",
          cursor: "pointer",
          "&:after,&:before": {
            position: "absolute",
            content: "''",
            border: "5px solid transparent",
            right: 5,

          },
          "&:after":{
            borderBottomColor: "#ffb069",
          },
          "&:before":{
            borderTopColor: "#ffb069",
            bottom: 7.5
          },
          "&.asc":{
            "&:after":{
              borderBottomColor: "#ffb069",
            },
            "&:before":{
              borderTopColor: "transparent",
            },
          },
          "&.desc":{
            "&:after":{
              borderBottomColor: "transparent",
            },
            "&:before":{
              borderTopColor: "#ffb069",
            },
          }
        },
      },
    },
    '& tbody': {
      [theme.breakpoints.mdDown]: {
        display: 'block',
      },
      '& tr': {
        '&:hover': {
          backgroundColor: 'rgba(208, 208, 208, 0.5)',
        },
        '& td': {
          wordBreak: 'break-word',
          '&:not(.fullwidth)': {
            [theme.breakpoints.mdDown]: {
              paddingLeft: '50% !important',
            },
          },
          '&::before': {
            [theme.breakpoints.mdDown]: {
              content: `attr(data-title)`,
              fontWeight: 550,
            },
          },
        },
        [theme.breakpoints.mdDown]: {
          paddingLeft: `${25}px`,
          paddingRight: `${25}px`,
        },
        '&:not(:last-child)': {
          [theme.breakpoints.mdDown]: {
            borderBottom: '1px solid rgba(120, 120, 120, 0.35)',
          },
        },
      },
    },
    "& tfoot":{
      [theme.breakpoints.mdDown]:{
        display: "block"
      },
    },
    '& tr': {
      [theme.breakpoints.mdDown]: {
        display: 'block',
      },
    },
    '& td, & th': {
      textAlign: 'left',
      padding: '10px 20px',
      fontSize: `${18 * theme.zoom}px`,
      [theme.breakpoints.mdDown]: {
        display: 'block',
      },
    },
    '& th': {
      fontWeight: 550,
    },
    '& td': {
      [theme.breakpoints.mdDown]: {
        position: 'relative',
      },
      '&::before': {
        content: "''",
        position: 'absolute',
        top: `${6}px`,
        left: `${6}px`,
        width: '45%',
        paddingRight: 10,
        whiteSpace: 'nowrap',
      },
    },
  },
  headerWrapper: {
    display: 'flex',
    
    [theme.breakpoints.mdDown]:{
      flexDirection: "column",
      paddingLeft: 10,
      paddingRight: 10,
    },
    '& > div:nth-child(1)': {
      flex: 2,
    },
    '& > div:nth-child(2)': {
      flex: 3,
      display: 'flex',
      // justifyContent: "space-between",
      justifyContent: 'flex-end',
      marginRight: 20,
    },
    sorter: {},
    search: {},
    paginationWrapper: {
      paginate: {},
      paginateList: {},
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    '& span': {
      padding: '10px 20px',
      borderRadius: '3px',
      boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)',
      cursor: 'pointer',
      userSelect: 'none',
      '&.active': {
        color: '#ffffff',
        backgroundColor: theme.color.secondary,
        cursor: 'no-drop',
      },
      '&.ellipse': {
        boxShadow: 'none',
        cursor: 'none',
      },
      [theme.breakpoints.mdDown]: {
        padding: '5px 10px',
        borderRadius: '2px',
        fontSize: 14,
      },
    },
  },
  paginationText: {
    fontSize: 14,
    [theme.breakpoints.mdDown]: {
      fontSize: 12,
    },
  },
  formInput: {
    padding: '10px !important',
  },
  formControl: {
    marginBottom: '15px !important',
  },

});
