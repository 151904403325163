import {GET_BULK_MERCHANTS, GET_CATEGORIES} from '../constant';
import { getApprovedData } from "../../helper/payarena";

export const categoryReducer = (state, action) => {
    const {payload} = action;
    switch (action.type) {
        case GET_CATEGORIES:

            return ({
                ...state,
                categories: {
                    ...payload,
                    data: getApprovedData(payload.data)
                }
            });
            case GET_BULK_MERCHANTS:
            return ({
                ...state,
                merchants: {
                    ...payload,
                    data: getApprovedData(payload.data, 'merchant')
                }
            });
        default:
        return state;
    }
};
