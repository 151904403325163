
import React from "react";
import PropTypes from "prop-types";

const Technology = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="35" height="23" viewBox="0 0 35 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M34.9812 15.8978C34.894 13.8925 34.5452 11.6692 34.0221 9.57667C33.499 7.57135 32.8451 5.69681 32.0604 4.25821C29.0088 -1.10385 25.6957 -0.275566 21.7286 0.77069C20.4644 1.07585 19.113 1.4246 17.718 1.55538H17.282C15.887 1.4246 14.5356 1.07585 13.2714 0.77069C9.30433 -0.231972 5.99119 -1.10385 2.93961 4.3018C2.15492 5.7404 1.45741 7.61494 0.97788 9.62027C0.454752 11.7128 0.106 13.9361 0.0188122 15.9414c0.0683758 18.1647 0.149594 19.7777 0.629128 20.9111C1.10866 21.9574 1.80617 22.6113 2.72164 22.8728C3.54993 23.0908 4.50899 23.0036 5.51165 22.5677C7.21182 21.8702 9.17355 20.388 11.0917 18.6006C12.4431 17.2928 14.9715 16.6389 17.5 16.6389C20.0285 16.6389 22.5569 17.2928 23.9083 18.6006C25.8265 20.388 27.7882 21.8702 29.4883 22.5677C30.491 22.96 31.4501 23.0908 32.2784 22.8728C33.1502 22.6113 33.8913 22.001 34.3709 20.8675C34.8504 19.7777 35.0684 18.1647 34.9812 15.8978ZM32.8887 20.2572C32.6271 20.8675 32.2784 21.2163 31.8424 21.347C31.3629 21.4778 30.7526 21.3906 30.0551 21.1291C28.5293 20.5188 26.7419 19.1674 24.9982 17.5108C23.3416 15.8542 20.4208 15.0259 17.5 15.0259C14.5792 15.0259 11.6584 15.8542 9.95824 17.4236C8.17089 19.0802 6.38353 20.4752 4.90134 21.0419C4.20384 21.3035 3.59352 21.4342 3.11399 21.2599C2.67805 21.1291 2.32929 20.7803 2.06773 20.17C1.71898 19.3417 1.5446 17.9903 1.63179 15.985C1.71898 14.1104 2.02414 11.9743 2.54726 9.96902C3.0268 8.09448 3.63711 6.35072 4.37821 5.0429C6.77588 0.727096 9.5659 1.4246 12.879 2.29648C14.2305 2.64523 15.6255 2.99398 17.1513 3.12476C17.1948 3.12476 17.1948 3.12476 17.2384 3.12476H17.718C17.7616 3.12476 17.7616 3.12476 17.8052 3.12476C19.3745 2.99398 20.7696 2.64523 22.121 2.29648C25.4341 1.46819 28.2241 0.727096 30.6218 5.0429C31.3629 6.35072 31.9732 8.05088 32.4527 9.96902C32.9323 11.9743 33.281 14.0669 33.3682 15.985C33.4554 17.9903 33.281 19.3417 32.8887 20.2572Z" fill={color}/>
<path d="M13.4896 7.39728C13.0537 7.00494 12.5306 6.69978 11.9203 6.65618C11.8767 6.08946 11.6151 5.52274 11.2228 5.13039L11.1792 5.0868C10.6996 4.60727 10.0893 4.3457 9.3918 4.3457C8.6943 4.3457 8.04039 4.65086 7.60445 5.0868C7.21211 5.47915 6.90695 6.04587 6.86335 6.65618C6.25304 6.69978 5.72991 6.96134 5.29397 7.35369L5.25038 7.39728C4.77084 7.87682 4.50928 8.48713 4.50928 9.18464C4.50928 9.88214 4.81444 10.5361 5.25038 10.972C5.68632 11.4079 6.20944 11.6695 6.86335 11.7131C6.90695 12.3234 7.16851 12.8901 7.60445 13.2825C8.08398 13.762 8.6943 14.0236 9.3918 14.0236C10.0893 14.0236 10.7432 13.7184 11.1792 13.2825C11.5715 12.8465 11.8767 12.3234 11.9203 11.7131C12.5306 11.6695 13.0973 11.4079 13.4896 10.972C13.9692 10.4925 14.2307 9.88214 14.2307 9.18464C14.2307 8.48713 13.9256 7.83322 13.4896 7.39728ZM12.3998 9.83855C12.2254 10.0129 12.0074 10.1001 11.7459 10.1001H11.1356C10.6996 10.1001 10.3073 10.4489 10.3073 10.9284V11.4951C10.3073 11.7567 10.2201 11.9747 10.0457 12.149C9.87134 12.3234 9.65337 12.4106 9.3918 12.4106C9.13024 12.4106 8.91227 12.3234 8.73789 12.149C8.56352 11.9747 8.47633 11.7567 8.47633 11.4951V10.8848C8.47633 10.4489 8.12758 10.0565 7.64804 10.0565H7.03773C6.77616 10.0565 6.5582 9.96933 6.38382 9.79495C6.25304 9.66417 6.12225 9.4462 6.12225 9.18464C6.12225 8.92307 6.20944 8.7051 6.38382 8.53073C6.38382 8.53073 6.38382 8.53073 6.42741 8.48713C6.60179 8.35635 6.81976 8.26916 7.03773 8.26916H7.64804C8.08398 8.26916 8.47633 7.92041 8.47633 7.44088V6.83056C8.47633 6.569 8.56352 6.35103 8.73789 6.17665C8.91227 6.00227 9.13024 5.91509 9.3918 5.91509C9.65337 5.91509 9.87134 6.00227 10.0457 6.17665C10.0457 6.17665 10.0457 6.17665 10.0893 6.22024C10.2201 6.39462 10.3073 6.61259 10.3073 6.83056V7.44088C10.3073 7.87682 10.656 8.26916 11.1356 8.26916H11.7459C12.0074 8.26916 12.2254 8.35635 12.3998 8.53073C12.5742 8.7051 12.6614 8.92307 12.6614 9.18464C12.6614 9.4462 12.5742 9.66417 12.3998 9.83855Z" fill={color}/>
<path d="M25.0858 7.61408C25.9285 7.61408 26.6116 6.93096 26.6116 6.08829C26.6116 5.24562 25.9285 4.5625 25.0858 4.5625C24.2432 4.5625 23.5601 5.24562 23.5601 6.08829C23.5601 6.93096 24.2432 7.61408 25.0858 7.61408Z" fill={color}/>
<path d="M25.0858 13.8055C25.9285 13.8055 26.6116 13.1224 26.6116 12.2797C26.6116 11.437 25.9285 10.7539 25.0858 10.7539C24.2432 10.7539 23.5601 11.437 23.5601 12.2797C23.5601 13.1224 24.2432 13.8055 25.0858 13.8055Z" fill={color}/>
<path d="M21.9901 10.7108C22.8328 10.7108 23.5159 10.0276 23.5159 9.18497C23.5159 8.3423 22.8328 7.65918 21.9901 7.65918C21.1475 7.65918 20.4644 8.3423 20.4644 9.18497C20.4644 10.0276 21.1475 10.7108 21.9901 10.7108Z" fill={color}/>
<path d="M28.1803 10.7108C29.023 10.7108 29.7061 10.0276 29.7061 9.18497C29.7061 8.3423 29.023 7.65918 28.1803 7.65918C27.3377 7.65918 26.6545 8.3423 26.6545 9.18497C26.6545 10.0276 27.3377 10.7108 28.1803 10.7108Z" fill={color}/>
</svg>

    )
}

Technology.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

Technology.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Technology
    